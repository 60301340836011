import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';
import Admin from '../Admin/Admin';
import classes from './FirstStep.module.css';
import { IonIcon } from '@ionic/react';
import Button from '../../UI/Button/Button';
import { addColor, deleteColor, updateDisplay } from '../../../api/cars.api';

export default function FirstStep() {
  const params = useParams();
  const navigate = useNavigate();
  const [car, setCar] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedPreview, setSelectedPreview] = useState();
  const [selectedDisplay, setSelectedDisplay] = useState();
  const [color, setColor] = useState('#ffffff');
  const imageChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const displayChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedDisplay(e.target.files[0]);
    }
  };
  const saveDisplay = async () => {
    setLoading(true);
    await updateDisplay(selectedDisplay, car._id)
      .then(res => {
        setSelectedDisplay();
        setCar(res.res);
        setLoading(false);
      })
      .catch(err => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}cars/car/${params.id}`)
      .then(res => {
        setCar(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err.response.status === 404)
          navigate('/notfound', { replace: true });
        setLoading(false);
      });
  }, [params.id, navigate]);
  const handleNext = () => {
    if (!car.bannerPictures || !car.bannerPictures.length)
      return alert('Add at least one color');
    navigate(`/admin/second_step/${car._id}`);
  };
  if (!params.id) return <Navigate to='/' replace={true} />;
  return (
    <Admin>
      {car && !loading && (
        <div className={classes.container}>
          <h1 style={{ marginTop: '50px' }}>Adding Banners</h1>
          <div
            className={classes.imagePreview}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundImage: selectedPreview
                ? `url(${process.env.REACT_APP_API_URL}static/images/testing/banners/${selectedPreview.image})`
                : 'unset',
              backgroundColor: !selectedPreview ? '#cacaca' : 'unset',
            }}
          ></div>
          <div>
            <h1>Uploaded Colors</h1>
            <h2>Add up to 4 color options</h2>
            {car.bannerPictures && car.bannerPictures.length ? (
              <div className={classes.itemContainer}>
                {car.bannerPictures.map(picture => (
                  <div key={picture.image} className={classes.colorContainer}>
                    <img
                      width='450px'
                      height='250px'
                      src={`${process.env.REACT_APP_API_URL}static/images/testing/banners/${picture.image}`}
                      alt=''
                    />
                    <div
                      style={{ backgroundColor: picture.color }}
                      className={classes.colorPreview}
                    />
                    <div>
                      <Button
                        text='preview'
                        onClick={() => setSelectedPreview(picture)}
                        style={{ margin: '10px' }}
                      />
                      <Button
                        text='delete'
                        onClick={() => {
                          deleteColor(car._id, picture._id).then(res =>
                            setCar(res.res)
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              'no uploaded colors'
            )}
          </div>
          {!car.bannerPictures || car.bannerPictures.length < 4 ? (
            <div className={classes.colorOptions}>
              <div className={classes.addOption}>
                <input
                  onChange={imageChange}
                  type='file'
                  name='banner'
                  hidden
                  id='banner'
                  accept='image/*'
                />
                <label
                  style={{
                    backgroundImage: selectedImage
                      ? `url(${URL.createObjectURL(selectedImage)})`
                      : ``,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                  className={classes.label}
                  htmlFor='banner'
                >
                  <IonIcon
                    size='large'
                    style={{ color: 'white' }}
                    icon='add-outline'
                  />
                </label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    gap: '30px',
                    margin: '15px',
                  }}
                >
                  <p>Choose diplay color</p>
                  <input
                    onChange={e => setColor(e.target.value)}
                    value={color}
                    type='color'
                    name='color'
                    id='color'
                  />
                </div>
                <Button
                  text='save'
                  onClick={() =>
                    addColor(color, selectedImage, params.id).then(res =>
                      setCar(res.res)
                    )
                  }
                  disabled={!color || !selectedImage}
                />
              </div>
            </div>
          ) : (
            <>
              <h2>Limit reached</h2>
              <h3 style={{ marginBottom: '50px' }}>
                You cannot add more than 4 color options
              </h3>
            </>
          )}
          <h1>Display picture</h1>
          <div className={classes.addOption}>
            <input
              onChange={displayChange}
              type='file'
              name='display'
              hidden
              id='display'
              accept='image/*'
            />
            <label
              style={{
                backgroundImage: selectedDisplay
                  ? `url(${URL.createObjectURL(selectedDisplay)})`
                  : `url(${process.env.REACT_APP_API_URL}static/images/testing/display/${car.displayPicture})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
              className={classes.label}
              htmlFor='display'
            >
              <IonIcon
                size='large'
                style={{ color: 'white' }}
                icon='add-outline'
              />
            </label>
            <Button
              onClick={saveDisplay}
              text={loading ? 'Loading ...' : 'save'}
              disabled={!selectedDisplay || loading}
            />
          </div>
        </div>
      )}

      <Button
        style={{ margin: '5px', textAlign: 'right' }}
        text='next'
        disabled={loading}
        onClick={handleNext}
      />
    </Admin>
  );
}
