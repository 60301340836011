import React from 'react';
import { useState } from 'react';
import styles from './ServicesMenu.module.css';
import { IonIcon } from '@ionic/react';
import { Tooltip } from '@mui/material';

export default function ServicesMenu({
  toggleAppointment,
  toggleQuotation,
  toggleServices,
  toggleDriveTest,
}) {
  const [wrapped, setWrapped] = useState(true);

  const toggleWrap = () => {
    setWrapped(prevState => !prevState);
  };
  return (
    <>
      {/* <div className={wrapped ? styles.wrapped : styles.container}>
        <Dropdown className={styles.dropdown} onClick={toggleWrap} />
        <div className={styles.wrapper}>
          {!wrapped && (
            <div className={styles.items}>
              <Appointment onClick={toggleAppointment} />
              <Quotation onClick={toggleQuotation} />
              <Services onClick={toggleServices} />
              <DriveTest onClick={toggleDriveTest} />
            </div>
          )}
        </div>
      </div> */}
      <div className={styles.refContainer}>
        <IonIcon
          style={{ fontSize: '20px' }}
          className={styles.expandIcon}
          icon='caret-down-outline'
          onClick={toggleWrap}
        />

        <div
          className={[
            styles.expandedContainer,
            wrapped ? '' : styles.expanded,
          ].join(' ')}
        >
          {!wrapped ? (
            <>
              <Tooltip
                placement='left'
                title={
                  <p style={{ padding: '5px 10px', fontSize: '15px' }}>
                    PRENDRE UN RENDEZ VOUS
                  </p>
                }
              >
                <IonIcon
                  style={{ fontSize: '20px' }}
                  icon='calendar-outline'
                  onClick={toggleAppointment}
                />
              </Tooltip>
              <Tooltip
                placement='left'
                title={
                  <p style={{ padding: '5px 10px', fontSize: '15px' }}>
                    DEMANDEZ UN DEVIS
                  </p>
                }
              >
                <IonIcon
                  style={{ fontSize: '20px' }}
                  icon='clipboard-outline'
                  onClick={toggleQuotation}
                />
              </Tooltip>
              <Tooltip
                placement='left'
                title={
                  <p style={{ padding: '5px 10px', fontSize: '15px' }}>
                    DEMANDER UN RENDEZ VOUS ENTRETIEN
                  </p>
                }
              >
                <IonIcon
                  style={{ fontSize: '20px' }}
                  icon='construct-outline'
                  onClick={toggleServices}
                />
              </Tooltip>
              <Tooltip
                placement='left'
                title={
                  <p style={{ padding: '5px 10px', fontSize: '15px' }}>
                    DEMANDER UN TEST DRIVE
                  </p>
                }
              >
                <IonIcon
                  style={{ fontSize: '20px' }}
                  icon='speedometer-outline'
                  onClick={toggleDriveTest}
                />
              </Tooltip>
            </>
          ) : (
            <div style={{ height: '115px' }} />
          )}
        </div>
      </div>
    </>
  );
}
