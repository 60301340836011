import React from 'react';
import { downloadBrochure } from '../../../api/cars.api';
import Button from '../Button/Button';
import styles from './SecondaryBanner.module.css';

export default function SecondaryBanner({ car }) {
  if (car)
    return (
      <div
        className={styles.bannerGrid}
        style={{
          background: `url(${process.env.REACT_APP_API_URL}static/images/testing/banners/${car.secondaryBanner})`,
          backgroundSize: 'cover',
        }}
      >
        <div className={styles.gradient} />
        <div className={styles.content}>
          <h3 className={styles.title}>
            {car.descriptionTitle ? car.descriptionTitle : 'Title Placeholder'}
          </h3>
          <p className={styles.description}>
            {car.description
              ? car.description
              : 'Langage stylistique structuré à l’extérieur pour une présence sportive et assurée L"intérieur moderne équipé de matériaux dehaute qualité'}
          </p>
          <Button
            text='Télécharger fiche technique'
            onClick={() =>
              downloadBrochure(car.pdfName, `Brochure ${car.brand} ${car.name}`)
            }
          />
        </div>
        <p></p>
      </div>
    );
  else return <div style={{ height: '150px' }}></div>;
}
