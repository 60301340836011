import React from 'react';
import styles from './Highlight.module.css';

export default function Highlight({ items, type, title }) {
  if (items)
    return (
      <div className={styles.container}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.itemContainer}>
          {items &&
            items.map(item => (
              <div key={item._id} className={styles.highlight}>
                <img
                  className={styles.newImage}
                  src={`${process.env.REACT_APP_API_URL}static/images/testing/${type}/${item.image}`}
                  alt=''
                />
                <div className={styles.text}>
                  <h3 className={styles.itemTitle}>{item.title}</h3>
                  <p className={styles.itemDescription}>{item.description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
      // <div className={styles.container}>
      //   <h1 className={styles.title}>{title}</h1>
      //   <div className={styles.itemContainer}>
      //     {items &&
      //       items.map(item => (
      //         <div key={item._id} className={styles.item}>
      //           <div className={styles.imagePlaceholder}>
      //             <img
      //               src={`${process.env.REACT_APP_API_URL}static/images/testing/${type}/${item.image}`}
      //               className={styles.image}
      //             />
      //           </div>
      //           <h3 className={styles.itemTitle}>{item.title}</h3>
      //           <p className={styles.itemDescription}>{item.description}</p>
      //         </div>
      //       ))}
      //   </div>
      // </div>
    );
  else return <div style={{ height: '200px' }} />;
}
