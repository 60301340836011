import axios from 'axios';

export const createPost = async (textObject, image, secondImage) => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  //validation
  if (!image || !secondImage)
    return { status: 'error', error: 'please uplaod image' };
  if (!(textObject.title && textObject.category && textObject.content))
    return { status: 'error', error: 'incomplete data' };
  const formData = new FormData();
  formData.append('image', image);
  formData.append('title', textObject.title);
  formData.append('banner', secondImage);
  formData.append('category', textObject.category);
  formData.append('content', textObject.content);
  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}posts`, formData, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      result = { status: 'success', data: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};
