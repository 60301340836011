import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './CarTypesNavigation.module.css';
import CarCard from '../CarCard/CarCard';

export default function CarTypesNavigation() {
  /* the menu where all types of the cars are founded */

  const [active, setActive] = useState('SUV');
  const [cars, setCars] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}cars/car_names`)
      .then(res => {
        setCars(res.data);
      })
      .catch(err => {});
  }, []);

  return (
    <div className={styles.flexContainer}>
      <div>
        <ul className={styles.carNavMenu}>
          <li
            className={[
              active === 'SUV' ? styles.active : styles.notActive,
              styles.item,
            ].join(' ')}
          >
            {/* change type when the change in type is applied   */}
            <Link to='/' onClick={() => setActive('SUV')}>
              Voitures & Suv
            </Link>
          </li>
          <li
            className={[
              active === 'Truck' ? styles.active : styles.notActive,
              styles.item,
            ].join(' ')}
          >
            <Link to='/' onClick={() => setActive('Truck')}>
              Camions & Pickups
            </Link>
          </li>
          <li
            className={[
              active === 'Mini Van' ? styles.active : styles.notActive,
              styles.item,
            ].join(' ')}
          >
            <Link to='/' onClick={() => setActive('Mini Van')}>
              Mini Vans
            </Link>
          </li>
        </ul>
        <div
          className={styles.line}
          style={{
            marginLeft:
              (active === 'Mini Van' && '67%') || (active === 'Truck' && '35%'),
          }}
        />
      </div>
      <div className={styles.cardContainer}>
        {cars &&
          cars
            .filter(item => item.display)
            .filter(
              (item, index) =>
                index < 3 &&
                (item.type === active ||
                  (item.type === 'Car' && active === 'SUV'))
            )
            .map(car => (
              <CarCard car={car} display={car.displayPicture} key={car._id} />
            ))}
      </div>
    </div>
  );
}
