import React, { useState, useEffect } from 'react';
import classes from './Catalogue.module.css';
import { Link } from 'react-router-dom';

export default function Catalogue({ isOpen, carNames }) {
  const [visible, setVisible] = useState(false);
  const [exist, setExist] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      setExist(true);
    } else {
      setVisible(false);
      setTimeout(() => {
        setExist(false);
      }, 300);
    }
  }, [isOpen]);
  return (
    <div
      style={{
        left:
          isOpen === 'SOUEAST'
            ? '0px'
            : isOpen === 'KING LONG'
            ? '170px'
            : '300px',
      }}
      className={[classes.catalogue, visible ? classes.visible : ''].join(' ')}
    >
      {carNames &&
        exist &&
        carNames
          .filter((item, index) => (item.brand === isOpen) & item.display)
          .map(item => (
            <Link to={`/car/${item._id}`} key={item._id}>
              <div className={classes.item}>
                <div className={classes.loader}>
                  <img
                    src={`${process.env.REACT_APP_API_URL}static/images/testing/thumbnail/${item.thumbnail}`}
                    alt=''
                  />
                </div>
                <p className={classes.title}>
                  {item.brand} {item.name}
                </p>
                <p>A partir de </p>
                <p>
                  {new Intl.NumberFormat('fr-tn', {
                    currency: 'TND',
                    style: 'currency',
                    maximumFractionDigits: 0,
                  }).format(item.price)}
                </p>
              </div>
            </Link>
          ))}
    </div>
  );
}
