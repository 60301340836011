import { IonIcon } from '@ionic/react';
import React from 'react';
import Button from '../../UI/Button/Button';
import Admin from '../Admin/Admin';
import classes from './AddPost.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { createPost } from '../../../api/posts.api';
import { useNavigate } from 'react-router';

export default function AddPost() {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState();
  const [selectedSecondImage, setSelectedSecondImage] = useState();
  const formik = useFormik({
    initialValues: {
      title: '',
      content: '',
      category: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      content: Yup.string().required('Content is required'),
      category: Yup.string().required('Category is required'),
    }),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async values => {
      if (!selectedImage) return alert('Please Select an image');
      await createPost(values, selectedImage, selectedSecondImage)
        .then(res => {
          if (res.status === 'success') {
            alert('Added post successfully');
            navigate('/admin/blog');
            console.log(res);
          }
        })
        .catch(err => alert('Error creating post'));
    },
  });

  const imageChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const secondImageChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedSecondImage(e.target.files[0]);
    }
  };
  return (
    <Admin>
      <div className={classes.container}>
        <h1 style={{ textAlign: 'center' }}>Ajouter un post</h1>
        <label
          style={{
            backgroundImage: selectedSecondImage
              ? `url(${URL.createObjectURL(selectedSecondImage)})`
              : null,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          htmlFor='second'
        >
          <IonIcon icon='add-outline' size='large' />
        </label>
        <input
          type='file'
          id='second'
          name='second'
          hidden
          accept='image/*'
          onChange={secondImageChange}
        />
        <input
          type='text'
          id='title'
          name='title'
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={classes.input}
          placeholder='Post Title'
        />
        {formik.errors.title && formik.touched.title && (
          <p className={classes.error}>{formik.errors.title}</p>
        )}
        <input
          type='text'
          id='category'
          name='category'
          value={formik.values.category}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={classes.input}
          placeholder='Category'
          accept='image/*'
        />
        {formik.errors.category && formik.touched.category && (
          <p className={classes.error}>{formik.errors.category}</p>
        )}
        <label
          style={{
            backgroundImage: selectedImage
              ? `url(${URL.createObjectURL(selectedImage)})`
              : null,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          htmlFor='image'
        >
          <IonIcon icon='add-outline' size='large' />
        </label>
        <input
          type='file'
          id='image'
          name='image'
          hidden
          onChange={imageChange}
        />
        <textarea
          value={formik.values.content}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={classes.textarea}
          name='content'
          id='content'
          cols='30'
          rows='20'
          placeholder='Content of your post'
        ></textarea>
        {formik.errors.content && formik.touched.content && (
          <p className={classes.error}>{formik.errors.content}</p>
        )}
        <Button text='button' onClick={formik.submitForm} />
      </div>
    </Admin>
  );
}
