import React from 'react';
import styles from './CarProps.module.css';
import rev from '../../../assets/rev.png';

export default function CarProps({ content }) {
  return (
    <div className={styles.container}>
      <div className={styles.first}>
        <img src={rev} alt='' />
        <div>
          {content ? content[0] : 'PUISSANCE(CH.DIN)'}
          <div className={styles.content}>
            {content ? content[1] : '105 CH'}
          </div>
        </div>
      </div>

      <div>
        {content ? content[2] : 'CYLINDREE'}
        <div className={styles.content}>
          {content[3] ? content[3] : '1499 CM³'}
        </div>
      </div>
      <div>
        {content ? content[4] : 'GARANTIE'}
        <div className={styles.content}>
          {content[5] ? content[5] : '6 ans ou 160.000 km'}
        </div>
      </div>
    </div>
  );
}
