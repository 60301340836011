import React, { useEffect, useState } from 'react';
import styles from './Footer.module.css';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const icons = [
  {
    icon: 'logo-facebook',
    link: 'https://www.facebook.com/people/SBNF-motors/100069700104475/',
  },
  {
    icon: 'logo-instagram',
    link: 'https://www.instagram.com/sbnf_motors/',
  },
];

export default function Footer() {
  const navigate = useNavigate();
  const [cars, setCars] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}cars/car_names`)
      .then(res => setCars(res.data));
  }, []);

  const [vehiclesExpand, setVehiclesExpand] = useState(false);
  const [contactExpand, setContactExpand] = useState(false);
  const [navigationExpand, setNavigationExpand] = useState(false);
  return (
    <>
      <div className={styles.footer}>
        <div
          onClick={() => setVehiclesExpand(prev => !prev)}
          className={[
            styles.horizontal,
            vehiclesExpand ? styles.expanded : null,
          ].join(' ')}
        >
          <p>
            Véhicules <IonIcon icon='caret-down-outline' />
          </p>
          {cars &&
            cars
              .filter((car, index) => car.display && index < 5)
              .map(car => (
                <p
                  key={car._id}
                  onClick={() => {
                    navigate(`/car/${car._id}`);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {car.name}
                </p>
              ))}
        </div>
        <div
          onClick={() => setContactExpand(prev => !prev)}
          className={[
            styles.horizontal,
            contactExpand ? styles.expanded : null,
          ].join(' ')}
        >
          <p>
            Contact <IonIcon icon='caret-down-outline' />
          </p>
          <p>Email:</p>
          <p> acceuil@sbnf-motors.com </p>
          <p>Ouverts: Lundi à samedi</p>
          <p>Horaire: 8:30-17:30</p>
          <p>Teléphone:+216 29 926 431</p>
        </div>
        <div
          onClick={() => setNavigationExpand(prev => !prev)}
          className={[
            styles.horizontal,
            navigationExpand ? styles.expanded : null,
          ].join(' ')}
        >
          <Link>
            Navigation <IonIcon icon='caret-down-outline' />
          </Link>
          <Link>Home </Link>
          <Link>Soueast</Link>
          <Link>King Long</Link>
          <Link>Huanghai</Link>
          <Link>Découvrir Nos Agences</Link>
        </div>
        <div className={styles.vertical}>
          <h3>Recever nos actualiés</h3>
          <p>
            Abonnez-vous et profitz de toutes nos exclusivité sur votre boite
            mail
          </p>
          <input
            placeholder='Exp: sbnf@domain.com'
            className={styles.input}
            type='text'
          />
        </div>
        <div className={styles.socials}>
          Suivez nous sur <br />
          <div className={styles.links}>
            {icons.map(item => (
              <IonIcon
                onClick={() => {
                  window.location.replace(item.link);
                }}
                size='large'
                key={item.icon}
                icon={item.icon}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
