import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';

export default function PrivateRoute({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return navigate('/', { replace: true });
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}users/verify`,
        { token },
        { headers: { Authorization: 'Bearer ' + token } }
      )
      .then(res => setLoading(false))
      .catch(err => {
        navigate('/', { replace: true });
        localStorage.removeItem('token');
        console.log(err);
      });
  }, [location, navigate]);

  return (
    <>
      {loading ? (
        <div
          style={{
            height: '100vh',
            width: '100%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TailSpin
            height='100'
            width='100'
            color='#00121d'
            ariaLabel='tail-spin-loading'
            radius='1'
            visible={true}
          />
        </div>
      ) : children ? (
        children
      ) : (
        <div>Private Route</div>
      )}
    </>
  );
}
