import React, { useState } from 'react';
import classes from './AddCar.module.css';
import Button from '../../UI/Button/Button';
import { IonIcon } from '@ionic/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addCar } from '../../../api/cars.api';
import Progress from '../../UI/Progress/Progress';
import { useNavigate } from 'react-router';
import Admin from '../Admin/Admin';

export default function AddCar() {
  const navigate = useNavigate();
  const [secondImage, setSecondImage] = useState();
  const [progress, setProgress] = useState();
  const [thumbnail, seTthumbnail] = useState();
  const [error, setError] = useState();
  const secondImageChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      setSecondImage(e.target.files[0]);
    }
  };
  const thumbnailChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      seTthumbnail(e.target.files[0]);
    }
  };
  const formik = useFormik({
    initialValues: {
      brand: '',
      name: '',
      range: '', //range becomes engine size
      power: '',
      price: '',
      type: '',
      description: '',
      title: '',
      warranty: '',
    },
    validationSchema: Yup.object({
      brand: Yup.string().required('Brand is required'),
      name: Yup.string().required('Name is required'),
      range: Yup.number('Range must be numeric').required(
        'Engine size is required'
      ),
      power: Yup.number('Power must be numeric').required('Power is required'),
      price: Yup.number('Price must be numeric').required('Price is required'),
      description: Yup.string().required('Description is required'),
      title: Yup.string().required('Title is required'),
      type: Yup.string().required('Type is required'),
      warranty: Yup.string().required('Warranty is required'),
    }),
    onSubmit: async values => {
      if (!secondImage) {
        alert('Please select a hero image');
        return alert('Please select a hero image');
      }
      if (!thumbnail) {
        alert('Please select a thumbnail image');
        return alert('Please select a thumbnail image');
      }
      const res = await addCar(secondImage, thumbnail, values);
      if (res && res.status === 'error') {
        setError(res.res);
        alert('Upload Error');
      }
      if (res && res.status === 'success') {
        navigate(`/admin/first_step/${res.res._id}`);
      }
    },
  });
  return (
    <Admin>
      <form onSubmit={formik.handleSubmit}>
        {progress && <Progress progress={progress} />}
        <div
          style={{
            backgroundSize: 'cover',
          }}
          className={[classes.banner, classes.bannerGrid].join(' ')}
        >
          <div className={classes.gradient} />
          <div className={classes.pageTitle}>
            <p>Add specs, thumbnail and banners</p>
            <p>Compress images to not slow down the website !</p>
          </div>
          <div className={classes.thumbnailContainer}>
            {thumbnail && (
              <img
                width={150}
                height={100}
                src={URL.createObjectURL(thumbnail)}
                alt='thumbnail'
              />
            )}
            <label className={classes.label} htmlFor='thumbnail'>
              <IonIcon size='large' icon='add-outline' />
              Add Thumbnail (transparent PNG)
            </label>
            <input
              className={classes.fileInput}
              type='file'
              id='thumbnail'
              hidden
              accept='.png, .jpg, .jpeg'
              onChange={thumbnailChange}
            />
          </div>
          <div className={classes.name}>
            <p className={classes.bannerDescription}>Compact, Design, Future</p>
            <h1 className={classes.bannerTitle}>
              <select
                className={[classes.select, classes.brandInput].join(' ')}
                name='brand'
                id='brand'
                value={formik.values.brand}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value=''>Select Brand</option>
                <option value='SOUEAST'>SOUEAST</option>
                <option value='KING LONG'>KING LONG</option>
                <option value='HUANGHAI'>HUANGHAI</option>
              </select>
              {formik.touched.brand && formik.errors.brand ? (
                <p className={classes.error}>{formik.errors.brand}</p>
              ) : null}
              <input
                className={classes.brandInput}
                placeholder='Name'
                type='text'
                id='name'
                name='name'
                onChange={formik.handleChange}
                value={formik.values.name}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name ? (
                <p className={classes.error}>{formik.errors.name}</p>
              ) : null}
            </h1>
          </div>
          <div className={classes.type}>
            <select
              className={[classes.select, classes.brandInput].join(' ')}
              name='type'
              id='type'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.type}
            >
              <option value=''>Select Type</option>
              <option value='SUV'>SUV</option>
              <option value='Car'>Car</option>
              <option value='Truck'>Truck</option>
              <option value='Mini Van'>Mini Van</option>
            </select>
            {formik.touched.type && formik.errors.type ? (
              <p className={classes.error}>{formik.errors.type}</p>
            ) : null}
          </div>
          <div className={classes.priceContainer}>
            {/* <label htmlFor='warranty'>price</label>
            <input
              type='text'
              id='price'
              name='price'
              placeholder='price'
              style={{ width: '200px', maxWidth: 'unset' }}
              pref
            /> */}
            <p style={{ textTransform: 'uppercase', marginBottom: '5px' }}>
              starting from
            </p>
            <p>
              <input
                className={classes.brandInput}
                placeholder='Price'
                type='text'
                id='price'
                name='price'
                onChange={formik.handleChange}
                value={formik.values.price}
                onBlur={formik.handleBlur}
              />{' '}
              TND
            </p>
            {formik.touched.price && formik.errors.price ? (
              <p className={classes.error}>{formik.errors.price}</p>
            ) : null}
          </div>
          <div className={classes.button}>
            <Button text='Placeholder' type='button' />
          </div>
          <div className={classes.specContainer}>
            {' '}
            <div>
              <p>max power</p>
              <p>
                <input
                  className={classes.brandInput}
                  placeholder='Power'
                  type='text'
                  id='power'
                  name='power'
                  onChange={formik.handleChange}
                  value={formik.values.power}
                  onBlur={formik.handleBlur}
                />
                +HP
              </p>
              {formik.touched.power && formik.errors.power ? (
                <p className={classes.error}>{formik.errors.power}</p>
              ) : null}
              {/* <p>official Epa range up to</p>
              <p>
                <input
                  className={classes.brandInput}
                  placeholder='Range'
                  type='text'
                  id='range'
                  name='range'
                  onChange={formik.handleChange}
                  value={formik.values.range}
                  onBlur={formik.handleBlur}
                />{' '}
                MI
              </p>
              {formik.touched.range && formik.errors.range ? (
                <p className={classes.error}>{formik.errors.range}</p>
              ) : null} */}
            </div>
            <div>
              <p>cylindrée</p>
              <p>
                <input
                  className={classes.brandInput}
                  placeholder='Cylindrée'
                  type='text'
                  id='range'
                  name='range'
                  onChange={formik.handleChange}
                  value={formik.values.range}
                  onBlur={formik.handleBlur}
                />{' '}
                CM3
              </p>
              {formik.touched.range && formik.errors.range ? (
                <p className={classes.error}>{formik.errors.range}</p>
              ) : null}
            </div>
            <div className={classes.last}>
              <p>warranty</p>
              <p>
                <input
                  className={classes.brandInput}
                  placeholder='Warranty'
                  type='text'
                  id='warranty'
                  name='warranty'
                  onChange={formik.handleChange}
                  value={formik.values.warranty}
                  onBlur={formik.handleBlur}
                />{' '}
                MI
              </p>
              {formik.touched.warranty && formik.errors.warranty ? (
                <p className={classes.error}>{formik.errors.warranty}</p>
              ) : null}
            </div>
          </div>
        </div>
        <h1 className={classes.descriptionBanner}>Description Banner</h1>

        <div
          style={{
            backgroundImage:
              secondImage && `url(${URL.createObjectURL(secondImage)})`,
            backgroundSize: 'cover',
          }}
          className={[classes.banner, classes.secondaryBanner].join(' ')}
        >
          <div className={classes.gradient} />
          <div className={classes.content}>
            <input
              type='text'
              placeholder='Title'
              name='title'
              id='title'
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.title && formik.errors.title ? (
              <p className={classes.error}>{formik.errors.title}</p>
            ) : null}
            <textarea
              className={classes.textArea}
              name='description'
              id='description'
              cols='30'
              rows='5'
              placeholder='Description'
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
            {formik.touched.description && formik.errors.description ? (
              <p className={classes.error}>{formik.errors.description}</p>
            ) : null}
            {/* <h3 className={classes.title}>Description Title Placeholder</h3>
          <p className={classes.description}>Description Content Placeholder</p> */}
            <Button text='placeholder' type='button' />
          </div>
          <div className={classes.secondaryImage}>
            <label className={classes.label} htmlFor='hero'>
              <IonIcon size='large' icon='add-outline' />
              Add Image
            </label>
            <input
              className={classes.fileInput}
              type='file'
              id='hero'
              hidden
              accept='.png, .jpg, .jpeg'
              onChange={secondImageChange}
            />
          </div>
        </div>
        {error && <div>{JSON.stringify(error)}</div>}
        <div className={classes.submit}>
          <Button
            type='submit'
            text='Submit'
            disabled={formik.errors.length || !thumbnail || !secondImage}
          />
        </div>
      </form>
    </Admin>
  );
}
