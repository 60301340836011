import React from "react";
import styles from "./SideButtons.module.css";

export default function SideButtons({ toggleFindCar, toggleDriveTest }) {
  return (
    <div className={styles.container}>
      <button className={styles.bookTestDrive} onClick={toggleDriveTest}>
        <div>Réserver un Test Drive</div>
      </button>
      <button className={styles.explore} onClick={toggleFindCar}>
        <div>Explorer</div>
      </button>
    </div>
  );
}
