import React from 'react';
import classes from './Design.module.css';

export default function Design({ car, galleryRef }) {
  if (car && car.horizontalDesign && car.verticalDesign)
    return (
      <div ref={galleryRef} className={classes.container}>
        <div
          className={[classes.imgContainer, classes.vertical].join(' ')}
          style={{ maxWidth: '600px' }}
          onClick={() => galleryRef.current.scrollIntoView()}
        >
          <p className={classes.text}>Extérieur</p>
          <svg
            className={classes.icon}
            width='30'
            height='30'
            viewBox='0 0 30 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M30 15C30 6.72 23.28 0 15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15ZM3 15C3 8.37 8.37 3 15 3C21.63 3 27 8.37 27 15C27 21.63 21.63 27 15 27C8.37 27 3 21.63 3 15ZM21 15L15 21L12.885 18.885L15.255 16.5H9V13.5H15.255L12.87 11.115L15 9L21 15Z'
              fill='white'
            />
          </svg>
          <img
            src={`${process.env.REACT_APP_API_URL}static/images/testing/verticalDesign/${car.verticalDesign}`}
            alt='vertical car'
            style={{ maxWidth: '100%' }}
            // width='600'
          />
        </div>
        <div className={classes.column}>
          <h1>DESIGN</h1>
          <p style={{ textAlign: 'start', marginBottom: '10px' }}>
            {car.designDescription
              ? car.designDescription
              : 'Lignes sensuelles, profil dynamique, flancs sculptés et nouvelle signature lumineuse avec éclairage full LED... Dès le premier regard, Nouvelle dx3 séduit par son style moderne et séduisant, sublimé par sa nouvelle teinte métallisée Orange Valencia'}
          </p>
          <div
            onClick={() => galleryRef.current.scrollIntoView()}
            className={classes.imgContainer}
          >
            <p className={classes.text}>Intérieur</p>

            <svg
              className={classes.icon}
              width='30'
              height='30'
              viewBox='0 0 30 30'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M30 15C30 6.72 23.28 0 15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15ZM3 15C3 8.37 8.37 3 15 3C21.63 3 27 8.37 27 15C27 21.63 21.63 27 15 27C8.37 27 3 21.63 3 15ZM21 15L15 21L12.885 18.885L15.255 16.5H9V13.5H15.255L12.87 11.115L15 9L21 15Z'
                fill='white'
              />
            </svg>
            <img
              src={`${process.env.REACT_APP_API_URL}static/images/testing/horizontalDesign/${car.horizontalDesign}`}
              alt='horizontal car'
              style={{ maxWidth: '100%' }}
              // height={500}
            />
          </div>
        </div>
      </div>
    );
  else return <div style={{ height: '150px' }}></div>;
}
