import React, { useEffect, useState } from 'react';
import Navigation from '../../UI/Navigation/Navigation';
import classes from './Post.module.css';
import Footer from '../../UI/Footer/Footer';
import { useParams, useNavigate } from 'react-router';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';
import Blogs from '../../UI/Blog/Blogs';

export default function Post() {
  const navigate = useNavigate();
  const params = useParams();
  const [post, setPost] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    if (!params.id) return navigate('/notfound', { replace: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}posts/${params.id}`)
      .then(res => {
        setPost(res.data);
        setLoading(false);
      })
      .catch(err => {
        navigate('/notfound', { replace: true });
      });
  }, [params, navigate]);

  return (
    <>
      <Navigation opaque={true} />
      {!loading && post ? (
        <div className={classes.content}>
          <div
            className={classes.banner}
            style={{
              backgroundImage: `url(${process.env.REACT_APP_API_URL}static/images/testing/posts/${post.summary})`,
            }}
          ></div>
          <div className={classes.line}></div>
          <div className={classes.info}>
            <p>
              Posted {new Date(post.created_at).toLocaleDateString('fr-fr')}
            </p>
          </div>
          <div className={classes.line}></div>
          <h1 className={classes.title}>{post.title}</h1>
          <article
            style={{ whiteSpace: 'pre-wrap' }}
            className={classes.textContent}
          >
            {post.content.replace(/\\n/g, <br></br>)}
          </article>
          <img
            className={classes.image}
            src={`${process.env.REACT_APP_API_URL}static/images/testing/posts/${post.image}`}
            alt='Post'
          />
        </div>
      ) : (
        <div className={classes.loading}>
          <TailSpin
            height='100'
            width='100'
            color='#00121d'
            ariaLabel='tail-spin-loading'
            radius='1'
            visible={true}
          />
        </div>
      )}
      <h2>Other articles</h2>
      <Blogs id={params.id} />
      <Footer />
    </>
  );
}
