import React, { useRef } from 'react';
import Modal from '../Modal/Modal';
import classes from './Menu.module.css';
import { useState } from 'react';
import Appointment from '../Appointment/Appointment';
import DriveTest from '../DriveTest/DriveTest';
import Quotation from '../Quotation/Quotation';
import Services from '../Services/Services';
import search from '../Navigation/Icons/Search.svg';
import SearchResults from '../SearchResults/SearchResults';

export default function Menu({ toggleModal, isOpen, cars }) {
  const [appointmentOpen, setAppointmentOpen] = useState(false);
  const [driveTestOpen, setDriveTestOpen] = useState(false);
  const [quotationOpen, setQuotationOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [search, setSearch] = useState();
  const searchRef = useRef();
  const toggleSearch = () => {
    setSearch(prev => {
      if (prev) setKeyword('');
      else searchRef.current.focus();
      return !prev;
    });
  };
  const toggleAppointment = () => {
    setAppointmentOpen(prevState => !prevState);
  };
  const toggleDriveTest = () => {
    setDriveTestOpen(prevState => !prevState);
  };
  const toggleQuotation = () => {
    setQuotationOpen(prevState => !prevState);
  };
  const toggleServices = () => {
    setServicesOpen(prevState => !prevState);
  };
  return (
    <>
      <Modal
        toggleModal={toggleModal}
        isOpen={isOpen}
        height={'100%'}
        width={'100%'}
        bgColor={'#012337'}
        noMargin={true}
      >
        <div className={classes.container}>
          <div className={classes.searchContainer}>
            <img className={classes.search} src={search} alt='' />
            {!cars && <SearchResults results={cars} />}
            <div className={classes.dash} />
          </div>
          <div className={classes.item} onClick={() => toggleAppointment()}>
            RESERVEZ UN RENDEZ VOUS
          </div>
          <div className={classes.item} onClick={() => toggleQuotation()}>
            DEMANDER UN DEVIS
          </div>
          <div className={classes.item} onClick={() => toggleServices()}>
            RESERVEZ RENDEZ VOUS ENTRETIEN
          </div>
          <div className={classes.item} onClick={() => toggleDriveTest()}>
            DEMANDER UN TEST DRIVE
          </div>
        </div>
      </Modal>

      <Appointment
        isOpen={appointmentOpen}
        setIsOpen={setAppointmentOpen}
        togglePopup={toggleAppointment}
      />
      <DriveTest
        isOpen={driveTestOpen}
        setIsOpen={setDriveTestOpen}
        togglePopup={toggleDriveTest}
      />
      <Quotation
        isOpen={quotationOpen}
        setIsOpen={setQuotationOpen}
        togglePopup={toggleQuotation}
      />
      <Services
        isOpen={servicesOpen}
        setIsOpen={setServicesOpen}
        togglePopup={toggleServices}
      />
    </>
  );
}
