import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import Banner from '../../UI/Banner/Banner';
import Highlight from '../../UI/Highlight/Highlight';
import SecondaryBanner from '../../UI/SecondaryBanner/SecondaryBanner';
import axios from 'axios';
import classes from './ThirdStep.module.css';
import { IonIcon } from '@ionic/react';
import Button from '../../UI/Button/Button';
import { addDesign } from '../../../api/cars.api';
import Admin from '../Admin/Admin';

export default function ThirdStep() {
  const navigate = useNavigate();
  const params = useParams();
  const [car, setCar] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [vertical, setVertical] = useState();
  const [horizontal, setHorizontal] = useState();
  const [description, setDescription] = useState();
  const handleVertical = e => {
    if (e.target.files && e.target.files.length > 0) {
      setVertical(e.target.files[0]);
    }
  };
  const handleHorizontal = e => {
    if (e.target.files && e.target.files.length > 0) {
      setHorizontal(e.target.files[0]);
    }
  };
  const submitHandler = async () => {
    if (!description) return alert('Please add a description');
    else {
      setLoading(true);
      const res = await addDesign(vertical, horizontal, description, params.id)
        .then(res => {
          if (res.status === 'success') {
            setCar(res.res);
            alert('Design updated');
            setLoading(false);
          }
        })
        .catch(err => {
          alert('Error uploading', 'Please try again');
          setError(res.message);
          setLoading(false);
        });
    }
  };
  const checkDisabled = () => {
    if (loading) return true;
    if (!description) return true;
    if (description === car.designDescription && !horizontal && !vertical)
      return true;
    return false;
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}cars/car/${params.id}`)
      .then(res => {
        setCar(res.data);
        setLoading(false);
        setDescription(res.data.designDescription);
      })
      .catch(err => {
        if (err.response.status === 404)
          navigate('/notfound', { replace: true });
        setError(err);
        setLoading(false);
      });
  }, [params.id, navigate]);
  return (
    <Admin>
      {' '}
      {car && (
        <>
          <h1 className={classes.help}>Add Design pictures and description</h1>
          <Banner car={car} />
          <Highlight
            title='Highlights'
            type='highlight'
            items={car.highlights}
          />
          <SecondaryBanner car={car} />
          <div className={classes.container}>
            <div
              className={[classes.imgContainer, classes.vertical].join(' ')}
              style={{ maxWidth: '600px' }}
            >
              <div className={classes.verticalInput}>
                <label
                  style={{
                    backgroundImage: vertical
                      ? `url(${URL.createObjectURL(vertical)})`
                      : car.verticalDesign &&
                        `url(${process.env.REACT_APP_API_URL}static/images/testing/verticalDesign/${car.verticalDesign})`,
                    // vertical && `url(${URL.createObjectURL(vertical)})`,
                    backgroundSize: 'cover',
                  }}
                  htmlFor='vertical'
                >
                  <IonIcon
                    size='large'
                    style={{
                      backgroundColor: '#cacaca77',
                      borderRadius: '50%',
                    }}
                    icon='add-outline'
                  />
                </label>
                <input
                  onChange={handleVertical}
                  hidden
                  type='file'
                  name='vertical'
                  id='vertical'
                />
              </div>
            </div>
            <div className={classes.column}>
              <h1>DESIGN</h1>
              <textarea
                className={classes.textArea}
                placeholder='Describe the design of the car'
                name='design'
                id='design'
                rows='5'
                value={description}
                onChange={e => setDescription(e.target.value)}
              ></textarea>
              <div className={classes.imgContainer}>
                <div className={classes.horizontalInput}>
                  <label
                    style={{
                      backgroundImage: horizontal
                        ? `url(${URL.createObjectURL(horizontal)})`
                        : car.horizontalDesign &&
                          `url(${process.env.REACT_APP_API_URL}static/images/testing/horizontalDesign/${car.horizontalDesign})`,
                      backgroundSize: 'cover',
                    }}
                    htmlFor='horizontal'
                  >
                    <IonIcon
                      size='large'
                      style={{
                        backgroundColor: '#cacaca77',
                        borderRadius: '50%',
                      }}
                      icon='add-outline'
                    />
                  </label>
                  <input
                    onChange={handleHorizontal}
                    hidden
                    type='file'
                    name='horizontal'
                    id='horizontal'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              text={loading ? 'Loading...' : 'Save'}
              disabled={checkDisabled()}
              onClick={submitHandler}
            />
            <Button
              text={loading ? 'Loading ...' : 'Next'}
              style={{ margin: '20px' }}
              disabled={
                !car.designDescription ||
                !car.verticalDesign ||
                !car.horizontalDesign ||
                loading
              }
              onClick={() => {
                navigate(`/admin/fourth_step/${params.id}`);
              }}
            />
          </div>
        </>
      )}
      {error && <div>Unknown error</div>}
    </Admin>
  );
}
