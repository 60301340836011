import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Banner from '../../UI/Banner/Banner';
import Design from '../../UI/Design/Design';
import Gallery from '../../UI/Gallery/Gallery';
import Highlight from '../../UI/Highlight/Highlight';
import SecondaryBanner from '../../UI/SecondaryBanner/SecondaryBanner';
import classes from './Car.module.css';
import { TailSpin } from 'react-loader-spinner';
import Navigation from '../../UI/Navigation/Navigation';
import Footer from '../../UI/Footer/Footer';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import Quotation from '../../UI/Quotation/Quotation';

export default function Car() {
  let carId = null;
  const galleryRef = useRef(null);
  const params = useParams();
  if (params.id) carId = params.id;
  const navigate = useNavigate();
  const [exterior, setExterior] = useState(true);
  const [error, setError] = useState(false);
  const [loading, setloading] = useState(true);
  const [car, setCar] = useState();
  const [quotationOpen, setQuotationOpen] = useState(false);
  const toggleQuotation = () => {
    setQuotationOpen(prev => !prev);
  };
  useEffect(() => {
    if (!carId) return navigate('/notfound', { replace: true });
    setloading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}cars/car/${carId}`)
      .then(res => {
        setCar(res.data);
        setloading(false);
      })
      .catch(err => {
        if (err?.response?.status === 404)
          navigate('/notfound', { replace: true });
        setError(err);
        setloading(false);
      });
  }, [params.id, carId, navigate]);
  return (
    <>
      <Navigation opaque={error} />

      {loading && (
        <div className={classes.loading}>
          <TailSpin
            height='100'
            width='100'
            color='#00121d'
            ariaLabel='tail-spin-loading'
            radius='1'
            visible={true}
          />
        </div>
      )}
      {car && !loading && (
        <>
          <Banner toggleQuotation={toggleQuotation} car={car} />
          <Highlight
            title='Points Forts'
            type='highlight'
            items={car.highlights}
          />
          <SecondaryBanner car={car} />
          <Design car={car} galleryRef={galleryRef} />
          <Highlight
            title='Fonctionnalités'
            type='functionality'
            items={car.functionality}
          />
          <h1 className={classes.galleryTitle}>
            Galerie {car.brand} {car.name}
          </h1>
          <Gallery
            galleryRef={galleryRef}
            exterior={exterior}
            setExterior={setExterior}
            type={exterior ? 'exterior' : 'interior'}
            items={exterior ? car.exteriorPictures : car.interiorPictures}
          />
        </>
      )}
      {error && !car && <div className={classes.loading}>Unknown Error</div>}
      <Quotation
        isOpen={quotationOpen}
        setIsOpen={setQuotationOpen}
        togglePopup={toggleQuotation}
      />
      <Footer />
    </>
  );
}
