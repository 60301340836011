import React, { useEffect, useState } from 'react';
import Admin from '../Admin/Admin';
import classes from './Devis.module.css';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { updateMessage } from '../../../api/services.api';

export default function Devis() {
  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'phone', headerName: 'Phone', width: 200 },
    { field: 'car', headerName: 'Car', width: 250 },
    { field: 'info', headerName: 'Info', width: 300 },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   width: 120,
    //   renderCell: params => (
    //     <Button
    //       onClick={() => {
    //         deleteQuotation(params.row.id)
    //           .then(res => {
    //             if (res.status === 'error')
    //               return alert('error deleting devis');
    //             alert('Succesfully deleted service');
    //             setAppointments(prev =>
    //               prev.filter(item => item._id !== params.row.id)
    //             );
    //           })
    //           .catch(err => alert('Error deleting service'));
    //       }}
    //       variant='contained'
    //       color='error'
    //     >
    //       Delete
    //     </Button>
    //   ),
    // },
  ];
  const [appointments, setAppointments] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}services/devis`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      })
      .then(res => setAppointments(res.data))
      .catch(err => setError(err));
  }, []);

  return (
    <Admin>
      <div className={classes.container}>
        <h1>Devis requests</h1>
        {appointments && (
          <DataGrid
            processRowUpdate={async params => {
              const res = await updateMessage(params);
              if (res.status === 'success') alert('Update successful');
              else return;
            }}
            experimentalFeatures={{ newEditingApi: true }}
            sx={{ width: '100%', borderRadius: '0' }}
            rowHeight={100}
            columns={columns}
            rows={appointments.map(item => ({
              id: item._id,
              email: item.email,
              phone: item.phone,
              name: item.name,
              car: item.car,
              info: 'Send devis to client',
            }))}
          />
        )}
        {error && <div>{JSON.stringify(error)}</div>}
      </div>
    </Admin>
  );
}
