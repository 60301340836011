import React, { useState, useEffect } from 'react';
import Admin from '../Admin/Admin';
import classes from './Configuration.module.css';
import { IonIcon } from '@ionic/react';
import axios from 'axios';
import Button from '../../UI/Button/Button';

export default function Configuration() {
  const [config, setConfig] = useState();
  const [error, setError] = useState();
  const [firstBanner, setFirstBanner] = useState();
  const [secondBanner, setSecondBanner] = useState();
  const [bannerText, setbannerText] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}config`)
      .then(res => {
        setConfig(res.data);
        setbannerText(res.data.bannerContent);
      })
      .catch(err => setError(err));
  }, []);
  //saving pictures
  const saveBanner = async () => {
    if (!firstBanner) return alert('Please upload an image');
    const formData = new FormData();
    formData.append('video', firstBanner);
    setLoading(true);
    axios
      .put(`${process.env.REACT_APP_API_URL}config/video`, formData, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      })
      .then(res => alert('Added successfully !'))
      .catch(err => alert('Upload Error'));
    setFirstBanner();
    setLoading(false);
  };
  const saveHero = async () => {
    if (!secondBanner) return alert('Please upload an image');
    const formData = new FormData();
    formData.append('image', secondBanner);
    setLoading(true);
    axios
      .put(`${process.env.REACT_APP_API_URL}config/hero`, formData, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      })
      .then(res => alert('Added successfully !'))
      .catch(err => alert('Upload Error'));
    setSecondBanner();
    setLoading(false);
  };
  const imageChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      setFirstBanner(e.target.files[0]);
    }
  };
  const imageChangeSecond = e => {
    if (e.target.files && e.target.files.length > 0) {
      setSecondBanner(e.target.files[0]);
    }
  };
  //saving banner content text
  const handleChange = (e, index) => {
    setbannerText(prev => {
      const newTab = [...prev];
      newTab[index] = e.target.value;
      return newTab;
    });
  };
  const handleSave = async () => {
    if ((bannerText.length !== 6) | !bannerText.every(value => !!value))
      return alert('Check input fields');
    setLoading(true);
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}config/banner`,
        { content: bannerText },
        {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        }
      )
      .then(res => {
        alert('Successfully updated');
        setConfig(res.data);
      })
      .catch(err => alert('Upload error'));
    setLoading(false);
  };
  return (
    <Admin>
      <div className={classes.container}>
        <h1>Home Page Configuration</h1>
        <h3 style={{ alignSelf: 'flex-start', margin: '10px' }}>
          Insert image or video
        </h3>
        <p style={{ alignSelf: 'flex-start', margin: '10px' }}>
          For performance reasons keep image under 300kb or video under 3mb
        </p>
        <p style={{ alignSelf: 'flex-start', margin: '10px' }}>
          Compress all images before uploading
        </p>
        <p style={{ alignSelf: 'flex-start', margin: '10px' }}>
          Accepted formats: png, jpeg, gif, mp4
        </p>
        {config && (
          <>
            <div
              className={classes.firstBanner}
              style={{
                backgroundImage: firstBanner
                  ? `url(${URL.createObjectURL(firstBanner)})`
                  : config.video &&
                    `url(${process.env.REACT_APP_API_URL}static/images/testing/config/${config.video})`,
                backgroundSize: 'cover',
              }}
            >
              <label className={classes.label} htmlFor='banner'>
                <IonIcon icon='add-outline' size='large' />
                Click to change video/image
              </label>
              <input
                type='file'
                hidden
                id='banner'
                onChange={imageChange}
                accept='image/* video/*'
              />
            </div>
            <Button onClick={saveBanner} text='Save' disabled={!firstBanner} />
            <div className={classes.inputGroup}>
              <input
                type='text'
                onChange={e => handleChange(e, 0)}
                value={bannerText[0]}
                placeholder='Title1'
              />
              <input
                type='text'
                onChange={e => handleChange(e, 2)}
                value={bannerText[2]}
                placeholder='Content1'
              />
              <input
                type='text'
                onChange={e => handleChange(e, 4)}
                value={bannerText[4]}
                placeholder='Title2'
              />
              <input
                type='text'
                onChange={e => handleChange(e, 1)}
                value={bannerText[1]}
                placeholder='Content2'
              />
              <input
                type='text'
                onChange={e => handleChange(e, 3)}
                value={bannerText[3]}
                placeholder='Title3'
              />
              <input
                type='text'
                onChange={e => handleChange(e, 5)}
                value={bannerText[5]}
                placeholder='Content3'
              />
            </div>
            <Button
              disabled={
                bannerText.every(value =>
                  config.bannerContent.find(el => el === value)
                ) | loading
              }
              text={loading ? 'Loading' : 'Save'}
              onClick={handleSave}
            />
            <h3 style={{ alignSelf: 'flex-start', margin: '10px' }}>
              Insert image for the second banner
            </h3>
            <div
              className={classes.secondBanner}
              style={{
                backgroundImage: secondBanner
                  ? `url(${URL.createObjectURL(secondBanner)})`
                  : config.hero &&
                    `url(${process.env.REACT_APP_API_URL}static/images/testing/config/${config.hero})`,
                backgroundSize: 'cover',
              }}
            >
              <label className={classes.label} htmlFor='second'>
                <IonIcon icon='add-outline' size='large' />
                Click to change image
              </label>
              <input
                type='file'
                hidden
                id='second'
                onChange={imageChangeSecond}
              />
            </div>
            <Button
              onClick={saveHero}
              text={loading ? 'Loading' : 'Save'}
              disabled={!secondBanner | loading}
            />
          </>
        )}
        {error && <p>Unknown Error</p>}
      </div>
    </Admin>
  );
}
