import React from 'react';
import Modal from '../Modal/Modal';
import Steps from '../Steps/Steps';
import styles from './FindCarPopup.module.css';
import { useState, useEffect } from 'react';
import Button from '../Button/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Logo from './icons/steering-wheel.png';

export default function FindCarPopup({ isOpen, setIsOpen, togglePopup }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [cars, setCars] = useState([]);
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}cars/car_names`)
      .then(res => {
        setCars(res.data);
      })
      .catch(err => {});
  }, []);

  const chooseCarRef = () => {
    if (formik.values.carBuild === 'any') {
      var closest = cars.reduce(function (prev, curr) {
        return Math.abs(curr.price - formik.values.budget) <
          Math.abs(prev.price - formik.values.budget)
          ? curr
          : prev;
      });
      return closest;
    }
    const chosen = cars.find(car => {
      if (formik.values.carBuild.toUpperCase() === car.type.toUpperCase())
        return car;
      return null;
    });
    if (!chosen) return cars[0];
    else return chosen;
  };

  const formik = useFormik({
    initialValues: {
      purpose: '',
      avgHours: '',
      carBuild: '',
      budget: '',
    },
    validationSchema: Yup.object({
      purpose: Yup.string().required(),
      avgHours: Yup.string().required(),
      carBuild: Yup.string().required(),
      budget: Yup.string().required(),
    }),

    onSubmit: async (values, { resetForm }) => {
      const chosen = chooseCarRef();
      setIsOpen(false);
      resetForm();
      MySwal.fire({
        title: (
          <div>
            {chosen.brand} {chosen.name}
          </div>
        ),
        text: 'pourrait être la voiture parfaite pour vous',
        imageUrl: Logo,
        imageHeight: 100,
        confirmButtonColor: 'rgb(22, 22, 42)',
        cancelButtonText: 'Annuler',
      });
    },
  });

  const renderSwitch = currentStep => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className={styles.description}>
              Dans quel but utilisez-vous votre voiture ?
            </div>
            <select
              className={styles.select}
              value={formik.values.purpose}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='purpose'
              id='purpose'
            >
              <option value='' disabled hidden>
                Sélectionner une option ...
              </option>
              <option value='long'>Longues Distances</option>
              <option value='short'>Courtes Distances</option>
            </select>
            {formik.touched.purpose && formik.errors.purpose ? (
              <p className={styles.error}>Veuillez remplir ce champ</p>
            ) : null}
          </>
        );
      case 2:
        return (
          <>
            <div className={styles.description}>
              Quel est le nombre moyen d'heures d'utilisation de votre voiture
              par jour ?
            </div>
            <select
              className={styles.select}
              value={formik.values.avgHours}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='avgHours'
              id='avgHours'
            >
              <option value='' disabled hidden>
                Sélectionner une option ...
              </option>
              <option value='1'>entre 1 et 3 heures</option>
              <option value='2'>entre 3 et 6 heures</option>
              <option value='3'>6 heures ou plus</option>
            </select>
            {formik.touched.avgHours && formik.errors.avgHours ? (
              <p className={styles.error}>Veuillez remplir ce champ</p>
            ) : null}
          </>
        );

      case 3:
        return (
          <>
            <div className={styles.description}>
              Quelle est votre construction de voiture préférée ?
            </div>
            <select
              className={styles.select}
              value={formik.values.carBuild}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='carBuild'
              id='carBuild'
            >
              <option value='' disabled hidden>
                Sélectionner une option ...
              </option>{' '}
              <option value='any'>N'importe</option>
              <option value='suv'>SUV</option>
              <option value='truck'>Camion</option>
            </select>
            {formik.touched.carBuild && formik.errors.carBuild ? (
              <p className={styles.error}>Veuillez remplir ce champ</p>
            ) : null}
          </>
        );

      case 4:
        return (
          <>
            <div className={styles.description}>Quel est votre budget ? </div>
            <select
              className={styles.select}
              value={formik.values.budget}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='budget'
              id='budget'
            >
              <option value='' disabled hidden>
                Sélectionner une option ...
              </option>
              <option value='80000'>80</option>
              <option value='10000'>100</option>
              <option value='12000'>120</option>
              <option value='14000'>140</option>
            </select>
            {formik.touched.budget && formik.errors.budget ? (
              <p className={styles.error}>Veuillez remplir ce champ</p>
            ) : null}
          </>
        );

      default:
        return '';
    }
  };

  return (
    <>
      <Modal
        toggleModal={togglePopup}
        isOpen={isOpen}
        width={'60%'}
        height={'90%'}
        bgColor={'#16162A'}
      >
        <div className={styles.container}>
          <div>
            <div className={styles.title}>Find the perfect car for you</div>

            <div className={styles.description}>
              Merci de remplir le formulaire ci dessous et sélectionner votre
              date et heure de rendez-vous avec notre service S.A.V
            </div>

            <div className={styles.progress}>
              {[...Array(4)].map((_, i) => (
                <Steps value={i + 1} currentStep={currentStep} key={i} />
              ))}
            </div>
          </div>

          <div className={styles.step}>{renderSwitch(currentStep)} </div>
          <div />
          <div className={styles.button}>
            <div className={styles.cancel}>
              <Button
                text={'Annuler'}
                light={0}
                onClick={() => {
                  setCurrentStep(prev => (prev - 1) % 5);
                  if (currentStep === 1) {
                    setCurrentStep(prev => prev + 1);
                    formik.handleReset();
                    setIsOpen(false);
                  }
                  formik.resetForm();
                }}
              />
            </div>
            <div className={styles.next}>
              <Button
                text={'Suivant'}
                light={1}
                type={'submit'}
                disabled={
                  (currentStep === 1 &&
                    ((formik.touched.purpose && formik.errors.purpose) ||
                      formik.values.purpose.length === 0)) ||
                  (currentStep === 2 &&
                    ((formik.touched.avgHours && formik.errors.avgHours) ||
                      formik.values.avgHours.length === 0)) ||
                  (currentStep === 3 &&
                    ((formik.touched.carBuild && formik.errors.carBuild) ||
                      formik.values.carBuild.length === 0)) ||
                  (currentStep === 4 &&
                    ((formik.touched.budget && formik.errors.budget) ||
                      formik.values.budget.length === 0))
                }
                onClick={() => {
                  setCurrentStep(prev => (prev + 1) % 5);
                  if (currentStep === 4) {
                    formik.handleSubmit();
                    setCurrentStep(prev => prev + 1);
                    setIsOpen(false);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
