import React from 'react';
import classes from './SearchResults.module.css';
import { Link } from 'react-router-dom';

export default function SearchResults({ results }) {
  return (
    <div className={classes.container}>
      {results.map(item => (
        <Link key={item._id} to={`/car/${item._id}`}>
          <div className={classes.item}>
            <div className={classes.loader}>
              <img
                src={`${process.env.REACT_APP_API_URL}static/images/testing/thumbnail/${item.thumbnail}`}
                alt=''
                height='80px'
                width='120px'
              />
            </div>
            {item.brand} {item.name}
          </div>
        </Link>
      ))}
    </div>
  );
}
