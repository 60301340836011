import React, { useEffect, useState } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router';
import axios from 'axios';
import Banner from '../../UI/Banner/Banner';
import classes from './SecondStep.module.css';
import SecondaryBanner from '../../UI/SecondaryBanner/SecondaryBanner';
import AddFeature from '../../UI/AddFeature/AddFeature';
import Button from '../../UI/Button/Button';
import Admin from '../Admin/Admin';
import { IonIcon } from '@ionic/react';
import { deleteFeature, saveBrochure } from '../../../api/cars.api';

export default function SecondStep() {
  const params = useParams();
  const navigate = useNavigate();
  const [car, setCar] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [brochure, setBrochure] = useState();
  const handleNext = () => {
    if (car.functionality.length < 3 || car.highlights.length < 3) {
      return alert('Finish adding features first');
    } else navigate(`/admin/third_step/${params.id}`);
  };
  const handleChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      setBrochure(e.target.files[0]);
    }
  };
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}cars/car/${params.id}`)
      .then(res => {
        setCar(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err.response.status === 404)
          navigate('/notfound', { replace: true });
        setError(err);
        setLoading(false);
      });
  }, [params.id, navigate]);

  //dont forget to make it only possible once
  if (!params.id) return <Navigate to='/' replace={true} />;
  return (
    <Admin>
      {car && !loading && (
        <>
          <h1 className={classes.help}>
            Adding highlights and functionalities
          </h1>
          <Banner car={car} />
          <div className={classes.container}>
            <h1 className={classes.title}>add highlights</h1>
            <div className={classes.itemContainer}>
              {car &&
                car.highlights &&
                car.highlights.map(item => (
                  <div key={item._id} className={classes.item}>
                    <span
                      onClick={() => {
                        deleteFeature(car._id, item._id, true).then(res =>
                          setCar(res.res)
                        );
                      }}
                      className={classes.closebtn}
                    >
                      ×
                    </span>
                    <img
                      src={`${process.env.REACT_APP_API_URL}static/images/testing/highlight/${item.image}`}
                      alt='Highlight'
                      className={classes.image}
                    />
                    <h3 className={classes.itemTitle}>{item.title}</h3>
                    <p className={classes.itemDescription}>
                      {item.description}
                    </p>
                  </div>
                ))}
              {(!car.highlights || car.highlights.length < 3) && (
                <AddFeature
                  name='highlight'
                  inputId='highlight'
                  id={params.id}
                  setCar={setCar}
                  feature='highlight'
                />
              )}
            </div>
          </div>
          <SecondaryBanner car={car} />{' '}
          <div
            className={[classes.container, classes.brochureContainer].join(' ')}
          >
            <h1 className={classes.title}>Upload Brochure</h1>

            <label htmlFor='brochure' className={classes.addContainer}>
              <IonIcon icon='add-outline' size='medium' />
            </label>
            <input
              accept='.pdf'
              type='file'
              hidden
              name='brochure'
              id='brochure'
              onChange={handleChange}
            />
            <div>
              {/* {brochure ? brochure.name : 'no brochure uplaoded'} */}
              {(car && car.pdfName) || brochure ? (
                <IonIcon
                  icon='checkmark-outline'
                  size='large'
                  color='success'
                />
              ) : (
                <IonIcon icon='close-outline' size='large' color='danger' />
              )}
              <p className={classes.shortText}>
                {brochure ? brochure.name : car.pdfName ? car.pdfName : ''}
              </p>
            </div>
            <Button
              text='save brochure'
              disabled={!brochure}
              onClick={async () => {
                saveBrochure(car._id, brochure).then(res => {
                  setCar(res.res);
                  console.log(res);
                });
                setBrochure();
              }}
            />
          </div>
          <div className={classes.container}>
            <h1 className={classes.title}>add functionalities</h1>
            <div className={classes.itemContainer}>
              {car &&
                car.functionality &&
                car.functionality.map(item => (
                  <div key={item._id} className={classes.item}>
                    <span
                      onClick={() => {
                        deleteFeature(car._id, item._id, false).then(res =>
                          setCar(res.res)
                        );
                      }}
                      className={classes.closebtn}
                    >
                      ×
                    </span>
                    <img
                      src={`${process.env.REACT_APP_API_URL}static/images/testing/functionality/${item.image}`}
                      alt='Functionality'
                      className={classes.image}
                    />
                    <h3 className={classes.itemTitle}>{item.title}</h3>
                    <p className={classes.itemDescription}>
                      {item.description}
                    </p>
                  </div>
                ))}
              {(!car.functionality || car.functionality.length < 3) && (
                <AddFeature
                  inputId='functionality'
                  name='functionality'
                  id={params.id}
                  setCar={setCar}
                  feature='functionality'
                />
              )}
            </div>
          </div>
          <div className={classes.submit}>
            <Button
              text='next step'
              type='submit'
              disabled={
                car.functionality.length < 3 || car.highlights.length < 3
              }
              onClick={handleNext}
            />
          </div>
        </>
      )}
      {error && <p>Unknown Error</p>}
    </Admin>
  );
}
