import axios from 'axios';

export const createAppointment = async (name, phone, email, date) => {
  if (!(name && phone && email && date)) return;

  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}services/appointment`, {
      name,
      phone,
      email,
      date,
    })
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};

export const createQuotation = async (name, phone, email, car) => {
  if (!(name && phone && email && car))
    return alert('Vérifiez les données entrées');
  // const json = JSON.stringify(Object.fromEntries(formData));
  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}services/devis`, {
      name,
      phone,
      email,
      car,
    })
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};

export const createService = async values => {
  if (
    !(
      values.garage &&
      values.type &&
      values.date &&
      values.time &&
      values.name &&
      values.phone &&
      values.email
    )
  )
    return alert('Vérifiez les données entrées');
  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}services/maintenance`, values)
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};

export const createTestDrive = async values => {
  if (!(values.name && values.phone && values.email && values.model))
    return alert('Vérifiez les données entrées');
  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}services/testdrive`, {
      ...values,
      car: values.model,
    })
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      console.log(err);
      result = { status: 'error', err };
    });
  return result;
};

export const updateAppointment = async appointment => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  let id = appointment.id;
  let result = null;
  await axios
    .put(
      `${process.env.REACT_APP_API_URL}services/appointment/${id}`,
      appointment,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    )
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};

export const deleteAppointment = async id => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  let result = null;
  await axios
    .delete(`${process.env.REACT_APP_API_URL}services/appointment/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};
export const deleteService = async id => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  let result = null;
  await axios
    .delete(`${process.env.REACT_APP_API_URL}services/maintenance/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};
export const deleteTestdrive = async id => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  let result = null;
  await axios
    .delete(`${process.env.REACT_APP_API_URL}services/testdrive/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};

export const updateMessage = async message => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  let id = message.id;
  let result = null;
  await axios
    .put(`${process.env.REACT_APP_API_URL}services/message/${id}`, message, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};

export const deleteQuotation = async id => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  let result = null;
  await axios
    .delete(`${process.env.REACT_APP_API_URL}services/devis/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};
