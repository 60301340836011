import React, { useEffect, useState } from 'react';
import classes from './Blog.module.css';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Button } from '@mui/material';
import { IonIcon } from '@ionic/react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Admin from '../Admin/Admin';

export default function Blog() {
  const navigate = useNavigate();
  const columns = [
    {
      field: 'thumbnail',
      headerName: 'Thumbnail',
      width: 200,
      renderCell: params => (
        <img
          src={`${process.env.REACT_APP_API_URL}static/images/testing/posts/${params.row.image}`}
          alt='car thumbnail'
          width='140px'
        />
      ),
    },
    { field: 'title', headerName: 'Title', width: 200 },
    { field: 'category', headerName: 'Category', width: 150 },
    { field: 'summary', headerName: 'Summary', width: 250 },
    { field: 'content', headerName: 'Content', width: 250 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 240,
      renderCell: params => (
        <div className={classes.buttonContainer}>
          <Link
            style={{ color: 'unset' }}
            to={`/post/${params.row.id}`}
            target='_blank'
          >
            <Button variant='outlined'>Visit</Button>
          </Link>
          <Button
            onClick={onDelete.bind(null, params.row.id)}
            variant='contained'
            color='error'
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
  const onDelete = async id => {
    if (window.confirm('Are you sure you want to delete this item ?'))
      axios
        .delete(`${process.env.REACT_APP_API_URL}posts/${id}`)
        .then(res => {
          alert('Post deleted successfully');
          setCars(prev => {
            return prev.filter(item => item._id !== id);
          });
        })
        .catch(err => {
          alert('Error deleting post');
        });
  };
  const [cars, setCars] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}posts`)
      .then(res => setCars(res.data))
      .catch(err => setError(err));
  }, []);

  return (
    <Admin>
      <div className={classes.container}>
        <h1>Blog Posts</h1>
        <div style={{ alignSelf: 'flex-end', margin: '5px' }}>
          <Button
            onClick={() => {
              navigate('/admin/add-post');
            }}
            variant='contained'
          >
            <IonIcon icon='add' /> Add Post
          </Button>
        </div>
        {cars && (
          <DataGrid
            sx={{ width: '100%', borderRadius: '0' }}
            rowHeight={100}
            columns={columns}
            rows={cars.map(item => ({
              id: item._id,
              title: item.title,
              summary: item.summary,
              content: item.content,
              category: item.category,
              image: item.image,
            }))}
          />
        )}
      </div>
      {error && <p>Unknown Error</p>}
    </Admin>
  );
}
