import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import classes from './Modal.module.css';
import { IonIcon } from '@ionic/react';

export default function Modal({
  toggleModal,
  isOpen,
  children,
  height,
  width,
  bgColor,
  noMargin,
  style,
}) {
  const [overlayStyle, setoverlayStyle] = useState([classes.overlay]);
  const ref = useRef();

  useEffect(() => {
    if (isOpen) {
      setoverlayStyle(prev => [...prev, classes.overlayActive]);
      document.body.style.overflow = 'hidden';
    }
    if (!isOpen) {
      setoverlayStyle([classes.overlay]);
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);
  // useEffect(() => {
  //   const checkIfClickedOutside = e => {
  //     // If the menu is open and the clicked target is not within the menu, then close it
  //     if (isOpen && ref.current && !ref.current.contains(e.target)) {
  //       handleClose();
  //     }
  //   };
  //   if (isOpen) {
  //     setTimeout(
  //       () => document.body.addEventListener('click', checkIfClickedOutside),
  //       200
  //     );
  //   }
  //   return () => {
  //     // Cleanup the event listener
  //     document.body.removeEventListener('click', checkIfClickedOutside);
  //   };
  // }, [isOpen, toggleModal]);

  const handleClose = e => {
    setoverlayStyle([classes.overlay]);
    setTimeout(() => {
      toggleModal();
    }, 300);
  };
  const handleOutsideClick = e => {
    if (e.target.id === 'modal') handleClose();
  };
  if (isOpen)
    return ReactDOM.createPortal(
      <div
        id='modal'
        onClick={handleOutsideClick}
        className={overlayStyle.join(' ')}
      >
        <div
          ref={ref}
          className={[classes.modal].join(' ')}
          style={{
            width: width,
            height: height,
            backgroundColor: bgColor,
            marginTop: noMargin ? '0' : '50px',
            ...style,
          }}
        >
          <IonIcon
            size='large'
            icon='close'
            style={{ color: 'white', padding: '10px' }}
            onClick={handleClose}
            className={classes.close}
          />
          {children}
        </div>
      </div>,
      document.getElementById('menu')
    );
  else return null;
}
