import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import Admin from '../Admin/Admin';
import classes from './UpdateCar.module.css';
import { useFormik } from 'formik';
import Button from '../../UI/Button/Button';
import {
  updateBanner,
  updateCar,
  updateThumbnail,
} from '../../../api/cars.api';
import { IonIcon } from '@ionic/react';

export default function UpdateCar() {
  const formik = useFormik({
    initialValues: {
      power: '',
      price: '',
      size: '',
      warranty: '',
    },
  });
  const params = useParams();
  const navigate = useNavigate();
  const [car, setCar] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedThumbnail, setSelectedThumbnail] = useState();
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}cars/car/${params.id}`)
      .then(res => {
        setCar(res.data);
        formik.setValues({
          power: res.data.power || '',
          price: res.data.price || '',
          size: res.data.range || '',
          warranty: res.data.warranty || '',
        });
        setLoading(false);
      })
      .catch(err => {
        if (err.response.status === 404)
          navigate('/notfound', { replace: true });
        setLoading(false);
      });
  }, [navigate, params.id]);
  const imageChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const thumbnailChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedThumbnail(e.target.files[0]);
    }
  };
  return (
    <Admin>
      {!loading && (
        <>
          <div className={classes.container}>
            <h1>Modify Car</h1>
            <form className={classes.formContainer}>
              <label htmlFor='price'>Price</label>
              <input
                value={formik.values.price}
                onChange={formik.handleChange}
                type='text'
                id='price'
                name='price'
                placeholder='Price'
              />
              <label htmlFor='power'>Power</label>
              <input
                value={formik.values.power}
                onChange={formik.handleChange}
                type='text'
                id='power'
                name='power'
                placeholder='Power'
              />
              <label htmlFor='cylindree'>Cylindre</label>
              <input
                value={formik.values.size}
                onChange={formik.handleChange}
                type='text'
                id='size'
                name='size'
                placeholder='Cylindre'
              />
              <label htmlFor='warranty'>Warranty</label>
              <input
                value={formik.values.warranty}
                onChange={formik.handleChange}
                type='text'
                id='warranty'
                name='warranty'
                placeholder='Warranty'
              />
            </form>
            <Button
              disabled={loading || formik.dirty}
              text='save'
              onClick={() => {
                updateCar(formik.values, params.id);
              }}
            />
          </div>
          <h1 style={{ margin: '10px' }}>Change Hero Image</h1>
          <div
            style={{
              backgroundImage: selectedImage
                ? `url(${URL.createObjectURL(selectedImage)})`
                : `url(${process.env.REACT_APP_API_URL}static/images/testing/banners/${car.secondaryBanner})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            className={classes.banner}
          >
            <label htmlFor='banner'>
              <IonIcon icon='add-outline' size='large' />
            </label>
            <input
              type='file'
              name='banner'
              id='banner'
              hidden
              accept='image/*'
              onChange={imageChange}
            />
          </div>
          <Button
            disabled={!selectedImage || loading}
            style={{ margin: '20px' }}
            text={loading ? 'Loading ...' : 'Save image'}
            onClick={() => {
              updateBanner(selectedImage, car._id).then(res => {
                setCar(res.res);
                setSelectedImage();
              });
            }}
          />
          <h1>Change thumbnail</h1>
          <div className={classes.thumbnailContainer}>
            <h3>Click to change</h3>
            <label className={classes.label} htmlFor='thumbnail'>
              <input
                type='file'
                name='thumbnail'
                id='thumbnail'
                hidden
                accept='image/*'
                onChange={thumbnailChange}
              />
              <img
                src={
                  selectedThumbnail
                    ? URL.createObjectURL(selectedThumbnail)
                    : `${process.env.REACT_APP_API_URL}static/images/testing/thumbnail/${car.thumbnail}`
                }
                alt=''
                style={{ margin: '20px', maxWidth: '70%' }}
              />
            </label>
            <Button
              style={{ margin: '20px' }}
              text='save thumbnail'
              disabled={loading || !selectedThumbnail}
              onClick={() =>
                updateThumbnail(selectedThumbnail, params.id).then(res => {
                  setCar(res.res);
                  setSelectedThumbnail();
                })
              }
            />
          </div>
          <Button
            text='next'
            onClick={() => navigate(`/admin/first_step/${params.id}`)}
          />
        </>
      )}
    </Admin>
  );
}
