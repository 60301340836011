import React from 'react';
import classes from './Progress.module.css';

export default function Progress({ progress }) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.progressBar}>
        <div
          style={{ width: `${progress * 500}px` }}
          className={classes.progressBarFill}
        ></div>
      </div>
    </div>
  );
}
