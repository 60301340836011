import React, { useEffect } from 'react';
import Modal from '../Modal/Modal';
import Steps from '../Steps/Steps';
import styles from './Services.module.css';
import { useState } from 'react';
import Button from '../Button/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { createService } from '../../../api/services.api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Select from 'react-select';

export default function Services({ togglePopup, isOpen, setIsOpen }) {
  const [currentStep, setCurrentStep] = useState(1);
  useEffect(() => {
    setCurrentStep(1);
    formik.resetForm();
  }, [isOpen]);

  const MySwal = withReactContent(Swal);
  const selectStyles = {
    control: styles => ({
      ...styles,
      color: 'white',
      backgroundColor: 'transparent',
      border: 'none',
      caretColor: 'transparent',
      width: window.innerWidth < 700 ? '85%' : '60%',
      ':focus': { outline: 'unset' },
    }),
    option: styles => ({
      ...styles,
      backgroundColor: '#16162a',
      padding: '10px 20px',
      paddingLeft: '5px',
      border: 'none',
      ':hover': {
        backgroundColor: '#033552',
      },
    }),
  };
  const formik = useFormik({
    initialValues: {
      garage: '',
      type: '',
      date: dayjs(),
      time: '',
      name: '',
      phone: '',
      email: '',
    },
    validationSchema: Yup.object({
      garage: Yup.string().required(),
      type: Yup.string().required(),
      date: Yup.string().required(),
      time: Yup.string().required(),
      name: Yup.string().required(),
      phone: Yup.number().required(),
      email: Yup.string().email().required(),
    }),

    onSubmit: async (values, { resetForm }) => {
      resetForm();
      const res = await createService(formik.values);
      if (res && res.status === 'error') {
        setIsOpen(false);
        MySwal.fire({
          title: 'Oops...',
          text: 'Une erreur est survenue!',
          icon: 'error',
          confirmButtonColor: 'rgb(22, 22, 42)',
        });
      }
      if (res && res.status === 'success') {
        setIsOpen(false);
        MySwal.fire({
          title: 'Merci!',
          text: 'Nous vous contacterons bientôt',
          icon: 'success',
          confirmButtonColor: 'rgb(22, 22, 42)',
        });
      }
    },
  });

  const renderSwitch = currentStep => {
    switch (currentStep) {
      case 1:
        return (
          <div className={styles.formContainer}>
            <div>
              <div className={styles.description}>Garage</div>
              {/* <select
                className={styles.select}
                value={formik.values.garage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='garage'
                id='garage'
              >
                <option value='' disabled hidden>
                  Sélectionner une option ...
                </option>{' '}
                <option value='Work'>Work</option>
                <option value='Watever'>Watever</option>
              </select> */}
              <Select
                styles={selectStyles}
                placeholder='Sélectionner emplacement du service ...'
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={[
                  { value: 'fouchena', label: 'Mghira Fouchena , Tunis' },
                  { value: 'kelibia', label: 'Kelibia , Nabeul' },
                ]}
                unstyled={true}
                className={styles.select}
                onChange={type => formik.setFieldValue('garage', type.value)}
              />
              {formik.touched.garage && formik.errors.garage ? (
                <p className={styles.error}>Veuillez remplir ce champ</p>
              ) : null}
            </div>
            <div>
              <div className={styles.description}>Service</div>
              <Select
                placeholder='Sélectionner une option ...'
                unstyled={true}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={selectStyles}
                className={styles.select}
                options={[
                  { value: 'entretien', label: 'Entretien' },
                  { value: 'diagnostique', label: 'Diagnostique Mécanique' },
                  { value: 'devis carosserie', label: 'Devis Carosserie' },
                ]}
                // value={formik.values.type}
                onChange={type => formik.setFieldValue('type', type.value)}
              />
              {formik.touched.type && formik.errors.type ? (
                <p className={styles.error}>Veuillez remplir ce champ</p>
              ) : null}
            </div>
          </div>
        );
      case 2:
        return (
          <>
            <div className={styles.date}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                InputProps={{
                  disableUnderline: true,
                }}
              >
                <Stack spacing={3} sx={{ width: '50%' }}>
                  <DesktopDatePicker
                    value={formik.values.date}
                    onChange={value => {
                      formik.setFieldValue('date', value);
                    }}
                    onBlur={formik.handleBlur}
                    inputFormat='MM/DD/YYYY'
                    renderInput={params => (
                      <TextField
                        variant='standard'
                        {...params}
                        sx={{
                          svg: { color: '#fff' },
                          input: { color: '#fff' },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: 'white',
                          },
                          '& .MuiInput-underline:before': {
                            borderBottomColor: 'white',
                          },
                        }}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>

              {formik.touched.date && formik.errors.date ? (
                <p className={styles.error}>Veuillez remplir ce champ</p>
              ) : null}
            </div>

            <div>
              <div className={styles.description}>
                Plages horaires disponibles pour Entretien-MB
              </div>
              <Select
                placeholder='Sélectionner une option ...'
                unstyled={true}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                styles={selectStyles}
                className={styles.select}
                options={[
                  { value: '8:30 - 10:30', label: '8:30 - 10:30' },
                  { value: '10:30 - 12:30', label: '10:30 - 12:30' },
                  { value: '12:30 - 13:30', label: '12:30 - 12:30' },
                  { value: '14:30 - 16:30', label: '14:30 - 16:30' },
                ]}
                onChange={type => formik.setFieldValue('time', type.value)}
              />
              {/* <select
                className={styles.select}
                name='time'
                id='time'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.time}
              >
                <option value='' disabled hidden>
                  Sélectionner une option ...
                </option>
                <option value='Work'>Work</option>
                <option value='Watever'>Watever</option>
              </select> */}
              {formik.touched.time && formik.errors.time ? (
                <p className={styles.error}>Veuillez remplir ce champ</p>
              ) : null}
            </div>
          </>
        );

      case 3:
        return (
          <div className={styles.formContainer}>
            <div className={styles.label}>Nom et prénom</div>

            <input
              className={styles.input}
              id='name'
              name='name'
              type='text'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <p className={styles.error}>Veuillez remplir ce champ</p>
            ) : null}

            <div className={styles.label}>Numéro de téléphone</div>

            <input
              className={styles.input}
              id='phone'
              name='phone'
              type='text'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <p className={styles.error}>Veuillez remplir ce champ</p>
            ) : null}

            <div className={styles.label}>Email</div>

            <input
              className={styles.input}
              id='email'
              name='email'
              type='email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <p className={styles.error}>Veuillez remplir ce champ</p>
            ) : null}
          </div>
        );

      default:
        return '';
    }
  };

  return (
    <Modal
      toggleModal={togglePopup}
      isOpen={isOpen}
      width={window.innerWidth < 1050 ? '85%' : '1050px'}
      height='700px'
      bgColor={'#16162A'}
    >
      <div className={styles.container}>
        <div>
          <div className={styles.title}>Services</div>
          <div className={styles.description}>
            Merci de remplir le formulaire ci dessous et sélectionner les
            informations relatives au rendez-vous avec notre service S.A.V
          </div>
          <div className={styles.progress}>
            {[...Array(3)].map((_, i) => (
              <Steps value={i + 1} currentStep={currentStep} key={i} />
            ))}
          </div>
        </div>

        <div className={styles.stepContent}>{renderSwitch(currentStep)} </div>
        <div className={styles.buttonContainer}>
          <div className={styles.cancel}>
            <Button
              text={'Annuler'}
              light={0}
              onClick={() => {
                setCurrentStep(prev => (prev - 1) % 4);
                if (currentStep === 1) setCurrentStep(prev => prev + 1);
                setIsOpen(false);
                formik.handleReset();
              }}
            />
          </div>
          <div className={styles.next}>
            <Button
              text={'Suivant'}
              disabled={
                (currentStep === 1 &&
                  ((formik.touched.garage && formik.errors.garage) ||
                    (formik.touched.type && formik.errors.type) ||
                    formik.values.garage.length === 0 ||
                    formik.values.type.length === 0)) ||
                (currentStep === 2 &&
                  ((formik.touched.date && formik.errors.date) ||
                    (formik.touched.time && formik.errors.time) ||
                    formik.values.date.length === 0 ||
                    formik.values.time.length === 0)) ||
                (currentStep === 3 &&
                  ((formik.touched.name && formik.errors.name) ||
                    (formik.touched.phone && formik.errors.phone) ||
                    (formik.touched.email && formik.errors.email) ||
                    formik.values.name.length === 0 ||
                    formik.values.phone.length === 0 ||
                    formik.values.email.length === 0))
              }
              type={'submit'}
              light={1}
              onClick={() => {
                setCurrentStep(prev => (prev + 1) % 4);
                if (currentStep === 3) {
                  formik.handleSubmit();
                  setCurrentStep(prev => prev + 1);
                  setIsOpen(false);
                }
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
