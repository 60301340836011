import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home';
import Car from './components/pages/Car/Car';
import Notfound from './components/pages/NotFound/Notfound';
import Post from './components/pages/Post/Post';
import ScrollToTop from './components/UI/ScrollToTop';
import SecondStep from './components/pages/SecondStep/SecondStep';
import ThirdStep from './components/pages/ThirdStep/ThirdStep';
import FourthStep from './components/pages/FourthStep/FourthStep';
import Login from './components/pages/Login/Login';
import CarsAdmin from './components/pages/CarsAdmin/CarsAdmin';
import Services from './components/pages/Services/Services';
import TestDrive from './components/pages/TestDrive/TestDrive';
import Messages from './components/pages/Messages/Messages';
import Devis from './components/pages/Devis/Devis';
import AddCar from './components/pages/AddCar/AddCar';
import Appointments from './components/pages/Appointments/Appointments';
import Configuration from './components/pages/Configuration/Configuration';
import Blog from './components/pages/Blog/Blog';
import AddPost from './components/pages/AddPost/AddPost';
import FirstStep from './components/pages/FirstStep/FirstStep';
import UpdateCar from './components/pages/UpdateCar/UpdateCar';

function App() {
  return (
    <div className='App'>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/car/:id' element={<Car />} />
        <Route path='/car' element={<Car />} />
        <Route path='/post/:id' element={<Post />} />
        <Route path='/post/' element={<Post />} />
        <Route path='/admin/add' element={<AddCar />} />
        <Route path='/admin/update/:id' element={<UpdateCar />} />
        <Route path='/admin/first_step/:id' element={<FirstStep />} />
        <Route path='/admin/second_step/:id' element={<SecondStep />} />
        <Route path='/admin/third_step/:id' element={<ThirdStep />} />
        <Route path='/admin/fourth_step/:id' element={<FourthStep />} />
        <Route path='/admin/login' element={<Login />} />
        <Route path='/admin/home' element={<CarsAdmin />} />
        <Route path='/admin/appointments' element={<Appointments />} />
        <Route path='/admin/services' element={<Services />} />
        <Route path='/admin/testdrive' element={<TestDrive />} />
        <Route path='/admin/contact' element={<Messages />} />
        <Route path='/admin/devis' element={<Devis />} />
        <Route path='/admin/configuration' element={<Configuration />} />
        <Route path='/admin/blog' element={<Blog />} />
        <Route path='/admin/add-post' element={<AddPost />} />
        <Route path='*' element={<Notfound />} />
      </Routes>
    </div>
  );
}

export default App;
