import React, { useEffect, useState } from 'react';
import Admin from '../Admin/Admin';
import classes from './Messages.module.css';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { updateMessage } from '../../../api/services.api';

export default function Messages() {
  const columns = [
    { field: 'name', headerName: 'Name', width: 170 },
    { field: 'email', headerName: 'Email', width: 170 },
    { field: 'phone', headerName: 'Phone', width: 170 },
    { field: 'message', headerName: 'Message', width: 250 },
    { field: 'note', headerName: 'Note', width: 400, editable: true },
  ];
  const [appointments, setAppointments] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}services/messages`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      })
      .then(res => setAppointments(res.data))
      .catch(err => setError(err));
  }, []);

  return (
    <Admin>
      <div className={classes.container}>
        <h1>Messages</h1>
        {appointments && (
          <DataGrid
            processRowUpdate={async params => {
              const res = await updateMessage(params);
              if (res.status === 'success') alert('Update successful');
              else return;
            }}
            experimentalFeatures={{ newEditingApi: true }}
            sx={{ width: '100%', borderRadius: '0' }}
            rowHeight={100}
            columns={columns}
            rows={appointments.map(item => ({
              id: item._id,
              email: item.email,
              phone: item.phone,
              name: item.name,
              message: item.message,
              note: item.note,
            }))}
          />
        )}
        {error && <div>{JSON.stringify(error)}</div>}
      </div>
    </Admin>
  );
}
