import React, { useEffect, useState } from 'react';
import classes from './CarsAdmin.module.css';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Button } from '@mui/material';
import { deleteCar } from '../../../api/cars.api';
import { IonIcon } from '@ionic/react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Admin from '../Admin/Admin';
import { Switch } from '@mui/material';

export default function CarsAdmin() {
  const navigate = useNavigate();
  const [toggleLoading, setToggleLoading] = useState(false);
  const handleDisplayToggle = id => {
    setToggleLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}cars/toggle_display`,
        {
          id,
        },
        {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        }
      )
      .then(res => {
        setToggleLoading(false);
        axios
          .get(`${process.env.REACT_APP_API_URL}cars/car_names`)
          .then(res => setCars(res.data))
          .catch(err => setError(err));
      })
      .catch(err => {
        setToggleLoading(false);
        alert('Upload error');
      });
  };
  const columns = [
    {
      field: 'thumbnail',
      headerName: 'Thumbnail',
      width: 200,
      renderCell: params => (
        <img
          src={`${process.env.REACT_APP_API_URL}static/images/testing/thumbnail/${params.row.thumbnail}`}
          alt='car thumbnail'
          width='140px'
        />
      ),
    },
    { field: 'brand', headerName: 'Brand', width: 150 },
    { field: 'name', headerName: 'Model', width: 150 },
    { field: 'type', headerName: 'Type', width: 150 },
    { field: 'price', headerName: 'Price', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      renderCell: params => (
        <div className={classes.buttonContainer}>
          <Link
            style={{ color: 'unset' }}
            to={`/car/${params.row.id}`}
            target='_blank'
          >
            <Button variant='outlined'>Visit</Button>
          </Link>
          <Link to={`/admin/update/${params.row.id}`}>
            <Button variant='outlined' color='secondary'>
              modify
            </Button>
          </Link>
          <Button
            onClick={onDelete.bind(null, params.row.id)}
            variant='contained'
            color='error'
          >
            Delete
          </Button>
        </div>
      ),
    },
    {
      field: 'display',
      headerName: 'Displayed',
      width: 100,
      renderCell: params => {
        return (
          <Switch
            onChange={handleDisplayToggle.bind(null, params.row.id)}
            checked={params.row.display}
            label='Displayed'
            disabled={toggleLoading}
          />
        );
      },
    },
  ];
  const onDelete = async id => {
    if (window.confirm('Are you sure you want to delete this item ?'))
      deleteCar(id)
        .then(res => {
          alert('Car deleted successfully');
          setCars(prev => {
            return prev.filter(item => item._id !== id);
          });
        })
        .catch(err => {
          alert('Error deleting car');
        });
  };
  const [cars, setCars] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}cars/car_names`)
      .then(res => setCars(res.data))
      .catch(err => setError(err));
  }, []);

  return (
    <Admin>
      <div className={classes.container}>
        <h1>Car List</h1>
        <div style={{ alignSelf: 'flex-end', margin: '5px' }}>
          <Button
            onClick={() => {
              navigate('/admin/add');
            }}
            variant='contained'
          >
            <IonIcon icon='add' /> Add Car
          </Button>
        </div>
        {cars && (
          <DataGrid
            sx={{ width: '100%', borderRadius: '0' }}
            rowHeight={100}
            columns={columns}
            rows={cars.map(item => ({
              id: item._id,
              thumbnail: item.thumbnail,
              brand: item.brand,
              name: item.name,
              type: item.type,
              price: item.price,
              display: item.display,
            }))}
          />
        )}
      </div>
      {error && <p>Unknown Error</p>}
    </Admin>
  );
}
