import { IonIcon } from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import Banner from '../../UI/Banner/Banner';
import Button from '../../UI/Button/Button';
import Design from '../../UI/Design/Design';
import Highlight from '../../UI/Highlight/Highlight';
import SecondaryBanner from '../../UI/SecondaryBanner/SecondaryBanner';
import classes from './FourthStep.module.css';
import { addGallery } from '../../../api/cars.api';
import Admin from '../Admin/Admin';

export default function FourthStep() {
  const navigate = useNavigate();
  const params = useParams();
  const [car, setCar] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [exterior, setExterior] = useState();
  const [interior, setInterior] = useState();
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}cars/car/${params.id}`)
      .then(res => {
        setCar(res.data);
        setLoading(false);
      })
      .catch(err => {
        if (err.response.status === 404)
          navigate('/notfound', { replace: true });
        setError(err);
        setLoading(false);
      });
  }, [params.id, navigate]);

  const handleSubmit = async () => {
    if (!exterior || !interior) {
      return alert('Please select images');
    }
    setLoading(true);
    await addGallery(exterior, interior, params.id)
      .then(res => {
        if (res.status === 'success') {
          setCar(res.res);
          alert('upload successful');
          navigate('/admin/home');
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <Admin>
      {car && (
        <>
          <h1 className={classes.help}>Add exterior and interior gallery</h1>
          <Banner car={car} />
          <Highlight
            title='Highlights'
            type='highlight'
            items={car.highlights}
          />
          <SecondaryBanner car={car} />
          <Design car={car} />
          <Highlight
            title='Functionality'
            type='functionality'
            items={car.functionality}
          />
          <h1>ADD GALLERY</h1>
          <label className={classes.outsideLabel} htmlFor='exterior'>
            <h2 style={{ margin: '20px' }}>
              Exterior <IonIcon icon='add-outline' />
            </h2>
          </label>
          <div className={classes.itemContainer}>
            {!exterior && (
              <div>
                <label className={classes.label} htmlFor='exterior'>
                  <IonIcon
                    size='large'
                    style={{
                      backgroundColor: '#cacaca77',
                      borderRadius: '50%',
                    }}
                    icon='add-outline'
                  />
                </label>
              </div>
            )}
            <input
              type='file'
              name='exterior'
              id='exterior'
              accept='image/*'
              hidden
              multiple
              maxLength={5}
              onChange={e => {
                setExterior(Array.prototype.slice.call(e.target.files));
              }}
            />
            {exterior &&
              exterior.map((item, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(item)}
                  width='320px'
                  height='200px'
                  alt='exterior preview'
                />
              ))}
            {!exterior &&
              car.exteriorPictures &&
              car.exteriorPictures.map(item => (
                <img
                  src={`${process.env.REACT_APP_API_URL}static/images/testing/exterior/${item}`}
                  width='320px'
                  height='200px'
                  alt='interior preview'
                />
              ))}
          </div>
          <label className={classes.outsideLabel} htmlFor='interior'>
            <h2 style={{ margin: '20px' }}>
              Interior <IonIcon icon='add-outline' />
            </h2>
          </label>
          <div className={classes.itemContainer}>
            {!interior && (
              <div>
                <label className={classes.label} htmlFor='interior'>
                  <IonIcon
                    size='large'
                    style={{
                      backgroundColor: '#cacaca77',
                      borderRadius: '50%',
                    }}
                    icon='add-outline'
                  />
                </label>
              </div>
            )}
            {interior &&
              interior.map((item, index) => (
                <img
                  src={URL.createObjectURL(item)}
                  width='320px'
                  height='200px'
                  alt='interior preview'
                />
              ))}
            {!interior &&
              car.interiorPictures &&
              car.interiorPictures.map(item => (
                <img
                  src={`${process.env.REACT_APP_API_URL}static/images/testing/interior/${item}`}
                  width='320px'
                  height='200px'
                  alt='interior preview'
                />
              ))}
          </div>
          <input
            type='file'
            name='interior'
            id='interior'
            hidden
            multiple
            maxLength={5}
            accept='image/*'
            onChange={e => {
              setInterior(Array.prototype.slice.call(e.target.files));
            }}
          />
        </>
      )}
      <div className={classes.buttonContainer}>
        <Button
          text={loading ? 'Loading...' : 'Save'}
          disabled={!interior || !exterior}
          onClick={handleSubmit}
        />
      </div>
      {error && <p>Unknown Error</p>}
    </Admin>
  );
}
