import React, { useState, useEffect } from 'react';
import wheel from './wheel.svg';
import classes from './Notfound.module.css';
import { Navigate } from 'react-router-dom';

export default function Notfound() {
  const [wait, setWait] = useState(false);
  useEffect(() => {
    setTimeout(() => setWait(true), 2000);
  }, []);

  return (
    <div className={classes.container}>
      <img className={classes.animated} src={wheel} alt='' width={300} />
      <div>
        <h1>404 Resource Not Found</h1>
        <p>Redirecting you home</p>
      </div>
      {wait && <Navigate to='/' replace={1} />}
    </div>
  );
}
