import React, { useState, useEffect, useRef } from 'react';
import styles from './Navigation.module.css';
import { Link } from 'react-router-dom';
import SearchLogo from './Icons/Search.svg';
import Hamburger from './Icons/Hamburger.svg';
import Menu from '../Menu/Menu';
import Catalogue from '../Catalogue/Catalogue';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SearchResults from '../SearchResults/SearchResults';
import logo from '../../../assets/logo.png';
import ServicesMenu from '../ServicesMenu/ServicesMenu';
import Services from '../Services/Services';
import Appointment from '../Appointment/Appointment';
import DriveTest from '../DriveTest/DriveTest';
import Quotation from '../Quotation/Quotation';

export default function Navigation({ opaque }) {
  const navigate = useNavigate();
  const [transparent, setTransparent] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [catalogue, setCatalogue] = useState(false);
  const [carNames, setCarNames] = useState();
  const [search, setSearch] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [results, setResults] = useState();
  const searchRef = useRef();
  const [findCarOpen, setFindCarOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [appointmentOpen, setAppointmentOpen] = useState(false);
  const [driveTestOpen, setDriveTestOpen] = useState(false);
  const [quotationOpen, setQuotationOpen] = useState(false);
  const toggleFindCar = () => {
    setFindCarOpen(prevState => !prevState);
  };
  const toggleServices = () => {
    setServicesOpen(prevState => !prevState);
  };
  const toggleAppointment = () => {
    setAppointmentOpen(prevState => !prevState);
  };
  const toggleDriveTest = () => {
    setDriveTestOpen(prevState => !prevState);
  };
  const toggleQuotation = () => {
    setQuotationOpen(prevState => !prevState);
  };
  const toggleMenu = e => {
    setShowMenu(prevState => !prevState);
  };
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 310;
      if (show) setTransparent(false);
      else setTransparent(true);
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const getCarNames = async () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}cars/car_names`)
        .then(res => setCarNames(res.data));
    };
    getCarNames();
  }, []);
  useEffect(() => {
    if (!carNames) return;
    if (!keyword.trim()) return setResults();
    const nameResult = carNames.filter(item =>
      item.name.toLowerCase().includes(keyword.toLowerCase())
    );
    const brandResult = carNames.filter(item =>
      item.brand.toLowerCase().includes(keyword.toLowerCase())
    );
    const arrayWithDuplicates = [...nameResult, ...brandResult].filter(
      item => item.display
    );
    setResults([...new Set(arrayWithDuplicates)]);
  }, [keyword, carNames]);
  const toggleCatalogue = brand => {
    setCatalogue(prevState => {
      if (prevState === brand) return false;
      else return brand;
    });
  };
  const toggleSearch = () => {
    setSearch(prev => {
      if (prev) setKeyword('');
      else searchRef.current.focus();
      return !prev;
    });
  };
  return (
    <div style={{ color: 'white' }}>
      <ServicesMenu
        toggleAppointment={toggleAppointment}
        toggleQuotation={toggleQuotation}
        toggleServices={toggleServices}
        toggleDriveTest={toggleDriveTest}
      />
      <Appointment
        isOpen={appointmentOpen}
        setIsOpen={setAppointmentOpen}
        togglePopup={toggleAppointment}
      />
      <DriveTest
        isOpen={driveTestOpen}
        setIsOpen={setDriveTestOpen}
        togglePopup={toggleDriveTest}
      />
      <Quotation
        isOpen={quotationOpen}
        setIsOpen={setQuotationOpen}
        togglePopup={toggleQuotation}
      />
      <Services
        isOpen={servicesOpen}
        setIsOpen={setServicesOpen}
        togglePopup={toggleServices}
      />
      <nav className={[styles.nav].join(' ')}>
        {' '}
        <Menu cars={carNames} toggleModal={toggleMenu} isOpen={showMenu} />
        <div
          className={[
            styles.background,
            transparent & !catalogue & !opaque & !search
              ? styles.transparent
              : styles.solid,
          ].join(' ')}
        ></div>{' '}
        <div className={[styles.box, styles.brands].join(' ')}>
          <p
            onClick={toggleCatalogue.bind(null, 'SOUEAST')}
            className={styles.brand}
          >
            SOUEAST
          </p>
          <p
            onClick={toggleCatalogue.bind(null, 'KING LONG')}
            className={styles.brand}
          >
            KING LONG
          </p>
          <p
            onClick={toggleCatalogue.bind(null, 'HUANGHAI')}
            className={styles.brand}
          >
            Huanghai
          </p>
        </div>
        <div className={[styles.box, styles.logo].join(' ')}>
          <p
            className={styles.title}
            onClick={() => navigate('/', { replace: true })}
          >
            <img src={logo} alt='' />
          </p>
        </div>
        <div className={[styles.box, styles.logos].join(' ')}>
          <div />
          <div className={styles.icons}>
            <div style={{ position: 'relative' }}>
              <input
                ref={searchRef}
                placeholder='Recherche..'
                className={[styles.input, !search ? styles.collapse : ''].join(
                  ' '
                )}
                type='text'
                value={keyword}
                onChange={e => {
                  setKeyword(e.target.value);
                }}
              />
              <img
                style={{ cursor: 'pointer' }}
                src={SearchLogo}
                alt='Search'
                onClick={toggleSearch}
              />
              {search && results && <SearchResults results={results} />}
            </div>
            {/* <div className={styles.separator} />
            <Link>
              <img src={Language} alt='Language' />
            </Link> */}
          </div>
          <Link onClick={toggleMenu}>
            <img src={Hamburger} alt='Menu' />
          </Link>
        </div>
      </nav>
      <Catalogue isOpen={catalogue} carNames={carNames} />
    </div>
  );
}
