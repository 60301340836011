import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import classes from './Gallery.module.css';

export default function Gallery({
  items,
  type,
  setExterior,
  exterior,
  galleryRef,
}) {
  const [zoomedImage, setZoomedImage] = useState();

  if (items)
    return (
      <>
        <div ref={galleryRef} className={classes.flexContainer}>
          <ul className={classes.toggle}>
            <li
              style={{ color: exterior ? 'black' : 'unset' }}
              onClick={() => setExterior(true)}
            >
              Extérieur
            </li>
            <li
              style={{ color: !exterior ? 'black' : 'unset' }}
              onClick={() => setExterior(false)}
            >
              Intérieur
            </li>
          </ul>
          <div
            className={classes.line}
            style={{ marginLeft: exterior ? '0' : '50%' }}
          />
        </div>
        <div className={classes.container}>
          {items.map(item => (
            <div className={classes.loader} key={item}>
              <img
                key={item}
                src={`${process.env.REACT_APP_API_URL}static/images/testing/${type}/${item}`}
                alt=''
                width={320}
                height={200}
                className={classes.image}
                onClick={() => {
                  setZoomedImage(
                    `${process.env.REACT_APP_API_URL}static/images/testing/${type}/${item}`
                  );
                }}
              />
            </div>
          ))}
        </div>
        <Modal
          bgColor='transparent'
          toggleModal={() => {
            setZoomedImage();
          }}
          height='100%'
          width='60%'
          isOpen={zoomedImage}
        >
          <div className={classes.preview}>
            <img src={zoomedImage} alt='' />
          </div>
        </Modal>
      </>
    );
  else return <div style={{ height: '200px' }} />;
}
