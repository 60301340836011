import React, { useEffect, useState } from 'react';
import Navigation from '../UI/Navigation/Navigation';
import carVideo from '../../assets/video.gif';
import styles from './Home.module.css';
import CarProps from '../UI/CarProps/CarProps';
import CarTypesNavigation from '../UI/CarTypesNavigation/CarTypesNavigation';
import Button from '../UI/Button/Button';
import Blogs from '../UI/Blog/Blogs';
import Footer from '../UI/Footer/Footer';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';
import heroImage from '../../assets/help.png';
import placeholder from '../../assets/video.gif';
import FindCarPopup from '../UI/FindCarPopup/FindCarPopup';
import Services from '../UI/Services/Services';
import Appointment from '../UI/Appointment/Appointment';
import DriveTest from '../UI/DriveTest/DriveTest';
import Quotation from '../UI/Quotation/Quotation';
import ServicesMenu from '../UI/ServicesMenu/ServicesMenu';
import SideButtons from '../UI/SideButtons/SideButtons';

export default function Home() {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState();
  const [findCarOpen, setFindCarOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [appointmentOpen, setAppointmentOpen] = useState(false);
  const [driveTestOpen, setDriveTestOpen] = useState(false);
  const [quotationOpen, setQuotationOpen] = useState(false);
  const [more, setMore] = useState(false);
  const [error, setError] = useState();
  const toggleFindCar = () => {
    setFindCarOpen(prevState => !prevState);
  };
  const toggleServices = () => {
    setServicesOpen(prevState => !prevState);
  };
  const toggleAppointment = () => {
    setAppointmentOpen(prevState => !prevState);
  };
  const toggleDriveTest = () => {
    setDriveTestOpen(prevState => !prevState);
  };
  const toggleQuotation = () => {
    setQuotationOpen(prevState => !prevState);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}config`)
      .then(res => {
        setConfig(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  const toggleMore = () => {
    setMore(prev => !prev);
  };
  return (
    <>
      <Navigation />
      {loading ? (
        <div className={styles.loading}>
          <TailSpin
            height='100'
            width='100'
            color='#00121d'
            ariaLabel='tail-spin-loading'
            radius='1'
            visible={true}
          />
        </div>
      ) : config ? (
        <div className={styles.textSettings}>
          <div className={styles.bannerContainer}>
            <div className={styles.overlay} />
            {config && config.video ? (
              config.video.endsWith('.mp4') ? (
                <video
                  className={styles.video}
                  src={
                    config.video
                      ? `${process.env.REACT_APP_API_URL}static/images/testing/config/${config.video}`
                      : `url(${placeholder})`
                  }
                  height='100%'
                  autoPlay={true}
                  loop={true}
                  muted={true}
                ></video>
              ) : (
                <img
                  className={styles.video}
                  src={
                    config.video
                      ? `${process.env.REACT_APP_API_URL}static/images/testing/config/${config.video}`
                      : `url(${placeholder})`
                  }
                  alt='banner'
                  height='100%'
                />
              )
            ) : (
              <img src={carVideo} className={styles.video} alt='banner' />
            )}
            <SideButtons
              toggleFindCar={toggleFindCar}
              toggleDriveTest={toggleDriveTest}
            />
            <CarProps content={config.bannerContent} />
          </div>
          <div className={styles.vehicules}>
            <h1 className={styles.title}>NOS VÉHICULES</h1>
            <p className={styles.description}>
              Nous fournissons une variété de voitures exclusives dans les
              moteurs sbnf. Découvrez la voiture idéale qui correspond à votre
              style de vie.
            </p>
          </div>
          <CarTypesNavigation />
          <div
            className={styles.help}
            style={{
              backgroundImage:
                config && config.hero
                  ? `url(${process.env.REACT_APP_API_URL}static/images/testing/config/${config.hero})`
                  : `url(${heroImage})`,
            }}
          >
            <div className={styles.helpGradient} />
            <h1>
              Aidez-nous à vous trouver
              <br />
              la voiture parfaite
            </h1>
            <p>
              répondez à quelques questions pour trouver votre voiture idéale
            </p>
            <Button
              text='explorer'
              light={1}
              className={styles.button}
              onClick={toggleFindCar}
            />
            <FindCarPopup
              isOpen={findCarOpen}
              setIsOpen={setFindCarOpen}
              togglePopup={toggleFindCar}
            />
          </div>
          <div className={styles.blogTitle}>
            <h1 className={styles.title}>Blog</h1>
            <p onClick={toggleMore} className={styles.description}>
              {more ? 'HIDE' : 'SHOW ALL'}
            </p>
          </div>
          <Blogs />
          <Services
            isOpen={servicesOpen}
            setIsOpen={setServicesOpen}
            togglePopup={toggleServices}
          />
          <div
            className={[styles.collapsable, more ? styles.expanded : null].join(
              ' '
            )}
          >
            <Blogs showMore={more} />
          </div>
          <Appointment
            isOpen={appointmentOpen}
            setIsOpen={setAppointmentOpen}
            togglePopup={toggleAppointment}
          />
          <DriveTest
            isOpen={driveTestOpen}
            setIsOpen={setDriveTestOpen}
            togglePopup={toggleDriveTest}
          />
          <Quotation
            isOpen={quotationOpen}
            setIsOpen={setQuotationOpen}
            togglePopup={toggleQuotation}
          />
          <ServicesMenu
            toggleAppointment={toggleAppointment}
            toggleQuotation={toggleQuotation}
            toggleServices={toggleServices}
            toggleDriveTest={toggleDriveTest}
          />
        </div>
      ) : (
        <div style={{ height: '100vh' }}>{JSON.stringify(error)}</div>
      )}
      <Footer />
    </>
  );
}
