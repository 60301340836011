import React from 'react';
import Modal from '../Modal/Modal';
import styles from './Appointment.module.css';
import Button from '../Button/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createAppointment } from '../../../api/services.api';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function Appointment({ togglePopup, isOpen, setIsOpen }) {
  const MySwal = withReactContent(Swal);

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      date: dayjs(),
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      phone: Yup.number().required(),
      email: Yup.string().required(),
      date: Yup.string().required(),
    }),

    onSubmit: async (values, { resetForm }) => {
      resetForm();
      const res = await createAppointment(
        formik.values.name,
        formik.values.phone,
        formik.values.email,
        formik.values.date
      );
      if (res && res.status === 'error') {
        setIsOpen(false);
        MySwal.fire({
          title: 'Oops...',
          text: 'Une erreur est survenue!',
          icon: 'error',
          confirmButtonColor: 'rgb(22, 22, 42)',
        });
      }
      if (res && res.status === 'success') {
        setIsOpen(false);
        MySwal.fire({
          title: 'Merci!',
          text: 'Nous vous contacterons bientôt',
          icon: 'success',
          confirmButtonColor: 'rgb(22, 22, 42)',
        });
      }
    },
  });

  return (
    <Modal
      toggleModal={togglePopup}
      isOpen={isOpen}
      width={window.innerWidth < 700 ? '85%' : '1050px'}
      // height={'90%'}
      height='800px'
      bgColor={'#16162A'}
      style={{ marginTop: '0px' }}
    >
      <div className={styles.container}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className={styles.title}>Prise de rendez-vous</div>

            <div className={styles.description}>
              Prenez rendez-vous en ligne à la date qui vous convient et nous
              reviendrons vers vous pour confirmer le rendez-vous.
            </div>
          </div>
          <h3>Vos données personnelles</h3>
          <div className={styles.formContainer}>
            <div>
              <div className={styles.label}>Nom et prénom</div>

              <input
                className={styles.input}
                // placeholder='Nom et prénom'
                type='text'
                id='name'
                name='name'
                onChange={formik.handleChange}
                value={formik.values.name}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name ? (
                <p className={styles.error}>Veuillez remplir ce champ</p>
              ) : null}
            </div>
            <div>
              <div className={styles.label}>Numéro de téléphone</div>

              <input
                className={styles.input}
                // placeholder="Numéro de téléphone"
                type='text'
                id='phone'
                name='phone'
                onChange={formik.handleChange}
                value={formik.values.phone}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <p className={styles.error}>Veuillez remplir ce champ</p>
              ) : null}
            </div>
            <div>
              <div className={styles.label}>Email</div>
              <input
                className={styles.input}
                // placeholder="Email"
                type='email'
                id='email'
                name='email'
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className={styles.error}>Veuillez remplir ce champ</p>
              ) : null}
            </div>
            <div>
              <div className={styles.label}>
                Choisisez la date qui vous convient pour un rendez-vous
              </div>
              <div className={styles.date}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  InputProps={{
                    disableUnderline: true,
                  }}
                >
                  <Stack spacing={3} sx={{ width: '50%' }}>
                    <DesktopDatePicker
                      value={formik.values.date}
                      onChange={value => {
                        formik.setFieldValue('date', value);
                      }}
                      inputFormat='MM/DD/YYYY'
                      renderInput={params => (
                        <TextField
                          variant='standard'
                          {...params}
                          sx={{
                            svg: { color: '#fff' },
                            input: { color: '#fff' },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: 'white',
                            },
                            '& .MuiInput-underline:before': {
                              borderBottomColor: 'white',
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </div>

              {formik.touched.date && formik.errors.date ? (
                <p className={styles.error}>Veuillez remplir ce champ</p>
              ) : null}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <div className={styles.cancel}>
              <Button
                text={'Annuler'}
                light={0}
                onClick={() => {
                  setIsOpen(false);
                  formik.handleReset();
                }}
              />
            </div>
            <div className={styles.send}>
              <Button
                text={'Envoyer'}
                light={1}
                type='submit'
                onClick={formik.handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
}
