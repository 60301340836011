import React from "react";
import styles from "./Steps.module.css";

export default function Steps(props) {
  return (
    <>
      <div className={styles.line} />
      <div
        className={[
          styles.cercle,
          props.currentStep === props.value ? styles.active : "",
        ].join(" ")}
      >
        {props.value}
      </div>
    </>
  );
}
