import axios from 'axios';

export const login = async (login, password) => {
  if (!login || !password) return alert('Missing data');
  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}users`, {
      login,
      password,
    })
    .then(res => {
      result = { status: 'success', token: res.data.token };
      localStorage.setItem('token', res.data.token);
    })
    .catch(err => {
      console.log(err);
      result = { status: 'error', error: err.message };
    });
  return result;
};
