import React, { useState, useEffect } from 'react';
import axios from 'axios';
import classes from './Blogs.module.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';

export default function Blogs({ id, showMore }) {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}posts`)
      .then(res => {
        setBlogs(res.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  }, [showMore]);

  return (
    <div className={classes.container}>
      {loading && !blogs && (
        <div className={classes.loading}>
          <TailSpin
            height='100'
            width='100'
            color='#00121d'
            ariaLabel='tail-spin-loading'
            radius='1'
            visible={true}
          />
        </div>
      )}
      {blogs &&
        blogs
          .filter((item, index) => {
            // if (showMore) {
            //   if (index > 3) return item;
            // } else if ((index < 3) & (item._id !== id))
            if (showMore & (index > 2)) return item;
            else if (!showMore & (index <= 2)) return item;
            return false;
          })
          .map(item => (
            <div
              key={item._id}
              className={classes.item}
              style={{ alignSelf: showMore ? 'flex-start' : 'unset' }}
            >
              <div
                onClick={() => navigate(`/post/${item._id}`, { replace: true })}
                className={classes.imageContainer}
              >
                <svg
                  className={[classes.text, classes.icon].join(' ')}
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M30 15C30 6.72 23.28 0 15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15ZM3 15C3 8.37 8.37 3 15 3C21.63 3 27 8.37 27 15C27 21.63 21.63 27 15 27C8.37 27 3 21.63 3 15ZM21 15L15 21L12.885 18.885L15.255 16.5H9V13.5H15.255L12.87 11.115L15 9L21 15Z'
                    fill='white'
                  />
                </svg>
                <p className={classes.text}>Read More</p>
                <img
                  src={`${process.env.REACT_APP_API_URL}static/images/testing/posts/${item.image}`}
                  alt=''
                  width={400}
                />
              </div>
              <div>
                <p className={classes.category}>{item.category}</p>
                <p className={classes.date}>
                  {new Date(item.created_at).toLocaleDateString('fr-fr')}
                </p>
              </div>
              <p>{item.title}</p>
              <Link style={{ fontSize: '12px' }} to={`/post/${item._id}`}>
                Read More
              </Link>
            </div>
          ))}
    </div>
  );
}
