import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import styles from './Quotation.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createQuotation } from '../../../api/services.api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default function Quotation({ togglePopup, isOpen, setIsOpen }) {
  const [cars, setCars] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState('');

  const MySwal = withReactContent(Swal);

  const handleChange = event => {
    setSelectedValue(event.target.value);
    formik.setFieldValue('model', event.target.value);
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      model: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      phone: Yup.number().required(),
      email: Yup.string().email().required(),
      model: Yup.string().required(),
    }),

    onSubmit: async (values, { resetForm }) => {
      resetForm();
      const res = await createQuotation(
        formik.values.name,
        formik.values.phone,
        formik.values.email,
        formik.values.model
      );
      if (res && res.status === 'error') {
        setIsOpen(false);
        MySwal.fire({
          title: 'Oops...',
          text: 'Une erreur est survenue!',
          icon: 'error',
          confirmButtonColor: 'rgb(22, 22, 42)',
        });
      }
      if (res && res.status === 'success') {
        setIsOpen(false);
        MySwal.fire({
          title: 'Merci!',
          text: 'Nous vous contacterons bientôt',
          icon: 'success',
          confirmButtonColor: 'rgb(22, 22, 42)',
        });
      }
    },
  });

  const controlProps = item => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'size-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}cars/car_names`)
      .then(res => {
        setCars(res.data);
      })
      .catch(err => {});
  }, []);

  return (
    <Modal
      toggleModal={togglePopup}
      isOpen={isOpen}
      width={window.innerWidth < 700 ? '85%' : '1050px'}
      // height={'80%'}
      bgColor={'#16162A'}
    >
      <div className={styles.container}>
        <div>
          <div className={styles.title}>Demandez un devis</div>

          <div className={styles.description}>
            Demandez un devis en ligne du produit désiré
          </div>
        </div>
        <h3>Vos données personnelles</h3>
        <div className={styles.formContainer}>
          <div>
            <div className={styles.label}>Nom et prénom</div>

            <input
              className={styles.input}
              type='text'
              id='name'
              name='name'
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
            />

            {formik.touched.name && formik.errors.name ? (
              <p className={styles.error}>Veuillez remplir ce champ</p>
            ) : null}
          </div>
          <div>
            <div className={styles.label}>Numéro de téléphone</div>

            <input
              className={styles.input}
              type='text'
              id='phone'
              name='phone'
              onChange={formik.handleChange}
              value={formik.values.phone}
              onBlur={formik.handleBlur}
            />

            {formik.touched.phone && formik.errors.phone ? (
              <p className={styles.error}>Veuillez remplir ce champ</p>
            ) : null}
          </div>
          <div>
            <div className={styles.label}>Email</div>

            <input
              className={styles.input}
              type='email'
              id='email'
              name='email'
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
            />

            {formik.touched.email && formik.errors.email ? (
              <p className={styles.error}>Veuillez remplir ce champ</p>
            ) : null}
          </div>
          <div>
            <div className={styles.label}>Model to try</div>

            <div>
              <FormControl name='model'>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='model'
                  id='model'
                >
                  {cars
                    .filter(car => car.display)
                    .map(car => (
                      <FormControlLabel
                        key={car._id}
                        value={formik.values.model}
                        control={
                          <Radio
                            {...controlProps(car.name)}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 14,
                                color: 'white',
                              },
                            }}
                          />
                        }
                        sx={{
                          '.css-fzg2r3-MuiButtonBase-root-MuiRadio-root.Mui-checked':
                            { color: 'white' },
                          mr: '50px',
                          mt: '10px',
                        }}
                        label={car.name}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.cancel}>
            <Button
              text={'Annuler'}
              light={0}
              onClick={() => {
                setIsOpen(false);
                formik.handleReset();
              }}
            />
          </div>
          <div className={styles.send}>
            <Button
              text={'Envoyer'}
              light={1}
              type='submit'
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
