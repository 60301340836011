import React, { useState, useEffect } from 'react';
import Navigation from '../../UI/Navigation/Navigation';
import classes from './Login.module.css';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../UI/Button/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { login } from '../../../api/user.api';
import axios from 'axios';

export default function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: Yup.object({
      login: Yup.string()
        .min(4, 'Must be 4 characters or more')
        .required('Login required'),
      password: Yup.string()
        .min(6, 'Must be 6 characters or more')
        .required('Password required'),
    }),
    onSubmit: async (values, helpers) => {
      setLoading(true);
      try {
        const res = await login(values.login, values.password);
        if (res.status === 'success') {
          setError();
          navigate('/admin/home');
        } else if (res.status === 'error') {
          setError(res.error);
          formik.resetForm();
        }
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    },
  });
  useEffect(() => {
    if (!localStorage.getItem('token')) return;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}users/verify`,
        { token: localStorage.getItem('token') },
        {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        }
      )
      .then(res => navigate('/admin/home'))
      .catch();
  }, [navigate]);

  return (
    <>
      <Navigation opaque={true} />
      <div className={classes.container}>
        <div className={classes.formContainer}>
          <h1>Login</h1>
          <p>
            If you're not an administrator, <Link to='/'>click here</Link>
          </p>
          <form
            onSubmit={e => {
              e.preventDefault();
              formik.submitForm();
            }}
          >
            <label htmlFor='login'>Login</label>
            <input
              disabled={loading}
              type='text'
              id='login'
              name='login'
              placeholder='Login'
              style={{
                borderBottom:
                  formik.errors.login &&
                  formik.touched.login &&
                  '2px solid red',
              }}
              value={formik.values.login}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.login && formik.errors.login && (
              <p className={classes.error}>{formik.errors.login}</p>
            )}
            <label htmlFor='password'>Password</label>
            <input
              disabled={loading}
              type='password'
              id='password'
              name='password'
              style={{
                borderBottom:
                  formik.errors.password &&
                  formik.touched.password &&
                  '2px solid red',
              }}
              placeholder='complexpassword123'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.password && formik.errors.password && (
              <p className={classes.error}>{formik.errors.password}</p>
            )}
            <Button
              className={classes.button}
              text={loading ? 'Loading...' : 'LOGIN'}
              type='submit'
              disabled={loading}
            />
            {error && <p className={classes.error}>{error}</p>}
          </form>
        </div>
        <div className={classes.image}></div>
      </div>
    </>
  );
}
