import React from 'react';
import styles from './Button.module.css';

export default function Button(props) {
  return (
    <button
      {...props}
      className={[
        props.className,
        styles.button,
        props.light ? styles.light : '',
      ].join(' ')}
      type={props.type}
      disabled={props.disabled}
      style={props.style}
    >
      {props.text}
    </button>
  );
}
