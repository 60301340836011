import React, { useEffect } from 'react';
import styles from './CarCard.module.css';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import Button from '../Button/Button';

export default function CarCard(props) {
  const navigate = useNavigate();
  const [style, setStyle] = useState([styles.card]);
  useEffect(() => {
    setStyle(prev => [...prev, styles.active]);
  }, []);
  return (
    <div className={styles.loader}>
      <div
        onClick={() => navigate(`/car/${props.car._id}`)}
        className={style.join(' ')}
        style={{
          backgroundImage: props.display
            ? `url(${process.env.REACT_APP_API_URL}static/images/testing/display/${props.display})`
            : `url(${process.env.REACT_APP_API_URL}static/images/testing/banners/${props.car.bannerPictures[0].image})`,
          backgroundSize: 'cover',
          backgroundPositionX: 'center',
        }}
      >
        <div className={styles.gradient}></div>
        <h1 className={styles.title}>
          {props.car.brand + ' ' + props.car.name}{' '}
        </h1>
        <div className={styles.button}>
          <Button
            style={{
              padding: '9px 32px',
              fontSize: '10px',
              position: 'relative',
            }}
            light='true'
            text='Explorer'
          />
        </div>
      </div>
    </div>
  );
}
