import { AppBar, Toolbar } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import PrivateRoute from '../PrivateRoute';
import classes from './Admin.module.css';
import CustomDrawer from '../CustomDrawer/CustromDrawer';

export default function Admin({ children }) {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/admin/login');
  };
  const handleHome = () => {
    navigate('/admin/home');
  };
  return (
    <PrivateRoute>
      <AppBar position='static' style={{ background: '#00121d' }}>
        <div className={classes.appbarContainer}>
          <Toolbar />
          <h1>SBNF Admin</h1>
          <h2 onClick={handleHome}>Dashboard</h2>
          <h2 onClick={handleLogout}>Logout</h2>
        </div>
      </AppBar>
      <CustomDrawer />
      <div
        style={{
          width: 'calc(100% - 240px)',
        }}
      >
        {children}
      </div>
    </PrivateRoute>
  );
}
