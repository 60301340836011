import React from 'react';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import { IonIcon } from '@ionic/react';
import { useNavigate } from 'react-router';

const drawerItems = [
  {
    text: 'Cars',
    path: '/admin/home',
    icon: 'car-outline',
  },
  {
    text: 'Appointments',
    path: '/admin/appointments',
    icon: 'calendar-outline',
  },
  {
    text: 'Service ',
    path: '/admin/services',
    icon: 'construct-outline',
  },
  {
    text: 'Test Drive',
    path: '/admin/testdrive',
    icon: 'speedometer-outline',
  },
  {
    text: 'Contact',
    path: '/admin/contact',
    icon: 'file-tray-stacked-outline',
  },
  {
    text: 'Devis',
    path: '/admin/devis',
    icon: 'calculator-outline',
  },
  {
    text: 'Blog',
    path: '/admin/blog',
    icon: 'chatbubbles-outline',
  },
];

export default function CustomDrawer() {
  const navigate = useNavigate();
  return (
    <Drawer
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
        },
      }}
      variant='permanent'
      anchor='right'
    >
      {' '}
      <Toolbar />
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => navigate('/admin/configuration', { replace: true })}
        >
          <ListItemIcon>
            <IonIcon icon='cog-outline' size='large' />
          </ListItemIcon>
          <ListItemText primary='Configuration' />
        </ListItemButton>
      </ListItem>
      <Divider />
      <List>
        {drawerItems.map((item, index) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(item.path);
              }}
            >
              <ListItemIcon>
                <IonIcon icon={item.icon} size='large' />
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
