import axios from 'axios';

export const addCar = async (secondBanner, thumbnail, textData) => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  if (!(secondBanner && thumbnail && textData)) return;
  const formData = new FormData();
  formData.append('secondaryBanner', secondBanner);
  formData.append('thumbnail', thumbnail);
  formData.append('brand', textData.brand);
  formData.append('price', textData.price);
  formData.append('name', textData.name);
  formData.append('power', textData.power);
  formData.append('warranty', textData.warranty);
  formData.append('type', textData.type);
  formData.append('range', textData.range);
  formData.append('descriptionTitle', textData.title);
  formData.append('description', textData.description);
  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}cars/first_step`, formData, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      console.log(res);
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      console.log(err);
      result = { status: 'error', err };
    });
  return result;
};

export const updateBanner = async (image, id) => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  if (!image || !id) return;
  const formData = new FormData();
  formData.append('secondaryBanner', image);
  formData.append('id', id);
  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}cars/update_banner`, formData, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      alert('update successfully');
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      alert('error updating');
      result = { status: 'error', err };
    });
  return result;
};

export const updateThumbnail = async (image, id) => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  if (!image) return;
  let result = null;
  const formData = new FormData();
  formData.append('thumbnail', image);
  formData.append('id', id);
  await axios
    .post(`${process.env.REACT_APP_API_URL}cars/update_thumbnail`, formData, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      alert('thumbnail update successfully');
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      console.log(err);
      alert('error updating thumbnail');
      result = { status: 'error', err };
    });
  return result;
};

export const updateCar = async (text, id) => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  if (!text.warranty || !text.power || !text.size || !text.price) return;
  let result = null;
  await axios
    .put(
      `${process.env.REACT_APP_API_URL}cars/update_car`,
      {
        power: text.power,
        price: text.price,
        range: text.size,
        warranty: text.warranty,
        id,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    )
    .then(res => {
      alert('update successfully');
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      console.log(err);
      alert('error updating');
      result = { status: 'error', err };
    });
  return result;
};

export const addColor = async (color, image, id) => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  if (!(color && image && id)) return console.log('missing data');
  const formData = new FormData();
  formData.append('color', color);
  formData.append('banner', image);
  formData.append('id', id);
  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}cars/add_banner`, formData, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      alert('color option added successfully');
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      alert('error adding color option');
      result = { status: 'error', err };
    });
  return result;
};
export const deleteColor = async (carId, colorId) => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  if (!(colorId && carId)) return console.log('missing data');
  let result = null;
  await axios
    .post(
      `${process.env.REACT_APP_API_URL}cars/delete_banner`,
      {
        id: carId,
        colorId,
      },
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    )
    .then(res => {
      alert('color option deleted successfully');
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      alert('error deleting color option');
      result = { status: 'error', err };
    });
  return result;
};

export const addFeature = async (image, textData, featureName) => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  if (featureName !== 'highlight' && featureName !== 'functionality')
    return console.log('problem here');
  if (
    !image ||
    !textData ||
    !textData.title ||
    !textData.description ||
    !textData.id
  )
    return;
  const formData = new FormData();
  formData.append(featureName, image);
  formData.append('title', textData.title);
  formData.append('description', textData.description);
  formData.append('id', textData.id);
  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}cars/add_${featureName}`, formData, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};
export const deleteFeature = async (carId, featureId, highlight) => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  if (!carId || !featureId) return alert('missing data');
  let result = null;
  await axios
    .post(
      `${process.env.REACT_APP_API_URL}cars/delete_${
        highlight ? 'highlight' : 'functionality'
      }`,
      { id: carId, highlightId: featureId },
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    )
    .then(res => {
      alert('feature deleted succesfully');
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      alert('error deleting feature');
      result = { status: 'error', err };
    });
  return result;
};

export const addDesign = async (vertical, horizontal, description, id) => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  const formData = new FormData();
  formData.append('verticalDesign', vertical);
  formData.append('horizontalDesign', horizontal);
  formData.append('description', description);
  formData.append('id', id);
  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}cars/add_design`, formData, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};

export const addGallery = async (exterior, interior, id) => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  if (!exterior || !interior || !id) return alert('Missing data');
  const formData = new FormData();
  exterior.forEach(element => {
    formData.append('exterior', element);
  });
  interior.forEach(element => {
    formData.append('interior', element);
  });
  formData.append('id', id);
  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}cars/add_gallery`, formData, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = { status: 'error', err };
    });
  return result;
};

export const deleteCar = async id => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  if (!id) return alert('Missing data');
  let result = null;
  await axios
    .delete(`${process.env.REACT_APP_API_URL}cars/delete_car/${id}`, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      result = { status: 'success', res: res.data };
    })
    .catch(err => {
      result = {
        status: 'error',
        err,
      };
    });
  return result;
};
export const saveBrochure = async (id, brochure) => {
  if (!brochure) return alert('File missing');
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  if (!id) return alert('Missing data');
  const formData = new FormData();
  formData.append('id', id);
  formData.append('brochure', brochure);
  let result = null;
  await axios
    .post(`${process.env.REACT_APP_API_URL}cars/add_brochure`, formData, {
      headers: { Authorization: 'Bearer ' + token },
    })
    .then(res => {
      result = { status: 'success', res: res.data };
      alert('File upload success');
    })
    .catch(err => {
      result = {
        status: 'error',
        err,
      };
      alert('Unknown Error');
    });
  return result;
};
export const downloadBrochure = async (name, newName) => {
  if (!name) return;
  await axios
    .get(
      `${process.env.REACT_APP_API_URL}static/images/testing/brochure/${name}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', newName + '.pdf');
      link.click();
    })
    .catch(err => console.log(err));
};
export const updateDisplay = async (image, id) => {
  const token = localStorage.getItem('token');
  if (!token) return { status: 'error', error: 'Token not provided' };
  if (!image || !id) return { status: 'error', error: 'Missing Data' };
  const formData = new FormData();
  formData.append('id', id);
  formData.append('display', image);
  let result = null;
  await axios
    .post(
      `${process.env.REACT_APP_API_URL}cars/add_display_picture`,
      formData,
      {
        headers: { Authorization: 'Bearer ' + token },
      }
    )
    .then(res => {
      result = { status: 'success', res: res.data };
      alert('File upload success');
    })
    .catch(err => {
      result = {
        status: 'error',
        err,
      };
      alert('Unknown Error');
    });
  return result;
};
