import React, { useEffect, useState } from 'react';
import Admin from '../Admin/Admin';
import classes from './Services.module.css';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Button } from '@mui/material';
import { deleteService } from '../../../api/services.api';

export default function Services() {
  const columns = [
    { field: 'name', headerName: 'Name', width: 170 },
    { field: 'email', headerName: 'Email', width: 170 },
    { field: 'phone', headerName: 'Phone', width: 170 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'type', headerName: 'Type', width: 150 },
    { field: 'garage', headerName: 'Garage', width: 150 },
    { field: 'time', headerName: 'Time', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      renderCell: params => (
        <Button
          onClick={() => {
            deleteService(params.row.id)
              .then(res => {
                alert('Succesfully deleted service');
                setAppointments(prev =>
                  prev.filter(item => item._id !== params.row.id)
                );
              })
              .catch(err => alert('Error deleting service'));
          }}
          variant='contained'
          color='error'
        >
          Delete
        </Button>
      ),
    },
  ];
  const [appointments, setAppointments] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}services/maintenance`, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      })
      .then(res => setAppointments(res.data))
      .catch(err => setError(err));
  }, []);

  return (
    <Admin>
      <div className={classes.container}>
        <h1>Service Requests</h1>
        {appointments && (
          <DataGrid
            sx={{ width: '100%', borderRadius: '0' }}
            rowHeight={100}
            columns={columns}
            rows={appointments.map(item => ({
              id: item._id,
              email: item.email,
              phone: item.phone,
              name: item.name,
              date: new Date(item.date).toDateString(),
              type: item.type,
              garage: item.garage,
              time: item.time,
            }))}
          />
        )}
        {error && <div>{JSON.stringify(error)}</div>}
      </div>
    </Admin>
  );
}
