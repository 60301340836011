import React, { useState } from 'react';
import styles from './Banner.module.css';
import Button from '../Button/Button';
import CountUp from 'react-countup';
import logo from '../../../assets/rev.png';

export default function Banner({ car, toggleQuotation }) {
  const [selectedBanner, setSelectedBanner] = useState(car.bannerPictures[0]);
  const format = new Intl.NumberFormat('fr-tn', {
    currency: 'TND',
    style: 'currency',
    maximumFractionDigits: 0,
  }).format;
  return (
    <div
      className={[styles.banner, styles.bannerGrid].join(' ')}
      style={{
        backgroundImage:
          selectedBanner &&
          `url(${process.env.REACT_APP_API_URL}static/images/testing/banners/${selectedBanner.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        color: 'white',
      }}
    >
      <div className={styles.gradient}></div>
      <div className={styles.colorSelector}>
        {car.bannerPictures &&
          car.bannerPictures.map(item => (
            <div
              onClick={() => setSelectedBanner(item)}
              key={item.image}
              className={styles.color}
              style={{ backgroundColor: item.color }}
            />
          ))}
      </div>
      <div className={styles.name}>
        <p className={styles.bannerDescription}>Compact, Design, Future</p>
        <h1 className={styles.bannerTitle}>{`${car.brand} ${car.name}`}</h1>
      </div>

      <div className={styles.button}>
        <div className={styles.priceContainer}>
          <p>A partir de </p>
          <h1>{car.price} DT</h1>
        </div>
        <Button
          style={{ fontSize: '18px' }}
          text='Devis Gratuit'
          onClick={toggleQuotation}
        />
      </div>
      <div className={styles.specContainer}>
        <img src={logo} alt='' />
        <div>
          <p>PUISSANCE (CH.DIN)</p>
          <p>
            {/* <CountUp
              end={car.power}
              start={car.power / 2}
              duration={1.5}
              suffix=' CH'
              useEasing={true}
            /> */}
            {car.power}
          </p>
        </div>
        <div>
          <p>cylindrée</p>
          <p>
            {car.range}
            {/* <CountUp
              end={car.range}
              start={car.range / 2}
              duration={1.5}
              suffix=' CM3'
              useEasing={true}
              // formattingFn={number => format(number)}
            /> */}
          </p>
        </div>
        <div className={styles.last}>
          <p>garantie</p>
          <p>
            {/* <CountUp
              end={car.power}
              start={car.power / 3}
              duration={1.5}
              suffix=' CH'
              useEasing={true}
            /> */}
            {car.warranty}
          </p>
        </div>
      </div>
    </div>
  );
}
