import React, { useState } from 'react';
import classes from './AddFeature.module.css';
import Button from '../Button/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IonIcon } from '@ionic/react';
import { addFeature } from '../../../api/cars.api';

export default function AddFeature({ id, feature, setCar, inputId, name }) {
  const formik = useFormik({
    initialValues: { id: id, title: '', description: '' },
    validationSchema: Yup.object({
      id: Yup.string().required('id is required'),
      title: Yup.string().required('Title is required'),
      description: Yup.string().required('Description is required'),
    }),
    onSubmit: async values => {
      if (!selectedImage) return alert('Please select an image');
      setLoading(true);
      const res = await addFeature(selectedImage, values, feature);
      if (res.status === 'success') {
        setCar(res.res);
        alert('Feature added successfully');
        formik.resetForm();
        setSelectedImage();
      } else {
        alert('Error adding feature');
      }
      setLoading(false);
    },
  });
  const [selectedImage, setSelectedImage] = useState();
  const [loading, setLoading] = useState(false);
  const imageChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  return (
    <div className={classes.item}>
      <input
        type='file'
        name={name}
        id={inputId}
        className={classes.file}
        accept='image/*'
        hidden
        onChange={imageChange}
      />
      <label
        style={{
          backgroundImage:
            selectedImage && `url(${URL.createObjectURL(selectedImage)})`,
          backgroundSize: 'cover',
        }}
        className={classes.label}
        htmlFor={inputId}
      >
        <IonIcon
          size='large'
          style={{ backgroundColor: '#cacaca77', borderRadius: '50%' }}
          icon='add-outline'
        />
      </label>

      <h3 className={classes.itemTitle}>
        <input
          className={classes.input}
          placeholder='Feature title'
          type='text'
          id='title'
          name='title'
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.title && formik.errors.title ? (
          <p className={classes.error}>{formik.errors.title}</p>
        ) : null}
      </h3>
      <div className={classes.itemDescription}>
        <textarea
          className={classes.textArea}
          name='description'
          id='description'
          cols='30'
          rows='5'
          placeholder='Feature description'
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        ></textarea>
        {formik.touched.description && formik.errors.description ? (
          <p>{formik.errors.description}</p>
        ) : null}
      </div>
      <Button
        onClick={formik.submitForm}
        type='submit'
        text={loading ? 'Loading..' : 'add highlight'}
      />
    </div>
  );
}
